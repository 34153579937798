Unit Holiday.ReturnTypes;

Interface

Uses
  System.SysUtils,
  System.Generics.Collections,
  Cyrus.Enumerations {$IFDEF PAS2JS},
  JS{$ENDIF};

Type

  THolidayVenue = Class
    VenueId: Integer;
    VenueName: String;
    VenueCode: String;
    IsAbroad: Boolean;
{$IFDEF PAS2JS}
    Procedure Load(AVenue: TJSObject);
{$ENDIF}
  End;

  THolidayHotel = Class
    HotelId: Integer;
    HotelCode: String;
    VenueId: Integer;
    HotelName: String;
    DogsAllowed: Boolean;
    Function Description: String;
{$IFDEF PAS2JS}
    Procedure Load(AHotel: TJSObject);
{$ENDIF}
  End;

  TBookableHoliday = Class
    HolidayId: Integer;
    HolidayRef: String;
    Title: String;
    HolidayType: String;
    StartDate: TDate;
    EndDate: TDate;
    Tutor: String;
    Availability: Integer;
    HotelId: Integer;
    VenueId: Integer;
    Confirmed: Boolean;
    Media: String;
  End;

  TWaitListStep = (wlNoAdd, wlAdd, wlComplete, wlEmailSent);

  THolidayBooking = Class
  Private
    Function GetWaitList: TWaitListStep;
    Procedure SetWaitList(Const Value: TWaitListStep);
    Function GetLatestBookingState: TBookingState;
    Procedure SetLatestBookingState(Const Value: TBookingState);
    Function GetRoom2TypeInt: Integer;
    Function GetRoomTypeInt: Integer;
  Public
    HolidayId: Integer;
    HolidayRef: String;
    HotelId: Integer;
    VenueId: Integer;
    BookingId: Integer;
    HotelName: String;
    VenueName: String;
    Painters: Integer;
    NonPainters: Integer;
    RoomType: TRoomType;
    RoomType2: TRoomType;
    RoomShare: Boolean;

    GuestName: String;
    StartDate: TDate;
    EndDate: TDate;
    BalanceDueDate: TDate;
    ChoiceComplete: Boolean;
    WaitListStep: String;
    TimeDataSaved: TDateTime;
    LatestBookingState: String;

    Function GuestCount: Integer;
    Function RoomCount: Integer;
    Procedure ClearHolidayInfo;
    Constructor Create;
    Property RoomTypeInt: Integer Read GetRoomTypeInt;
    Property RoomType2Int: Integer Read GetRoom2TypeInt;

    Property WaitList: TWaitListStep Read GetWaitList Write SetWaitList;
    Property HighestBookingState: TBookingState Read GetLatestBookingState Write SetLatestBookingState;
{$IFDEF PAS2JS}
    Procedure Load(ABooking: TJSObject);
{$ENDIF}
  End;

  TSummaryBooking = Class
    BookingId: Integer;
    BookingRef: String;
    BookingState: String;
    HolidayId: Integer;
    HotelId: Integer;
    VenueId: Integer;
    HotelName: String;
    VenueName: String;
    HolidayName: String;
    Painters: Integer;
    NonPainters: Integer;
    ArrivalDate: TDate;
    DepartureDate: TDate;
    DateRange: String;
    BalanceDueDate: TDate;
    HolidayPrice: Double;
    TotalPaid: Double;
    DepositDue: Double;
    BalanceDue: Double;
    HolidayConfirmed: Boolean;
  End;

  TBasicPerson = Class
    CustomerId: Integer;
    FirstName: String;
    LastName: String;
    Address: String;
{$IFDEF PAS2JS}
    Procedure Load(Value: TJSObject); Virtual;
{$ENDIF}
    Function FullName: String;
  End;

  TBasicPersonList = Class(TList<TBasicPerson>)
  Private
  Public
    Function IdExists(Const Value: Integer): Boolean;
    Procedure AddPerson(Const ACustomerId: Integer; Const AFirstName, ALastName, AAddress: String);
  End;

  TBankDetails = Class
    AccountName: String;
    TradingName: String;
    SortCode: String;
    AccountNumber: String;
    IBAN: String;
    Swift: String;
    ChequeLimit: Integer;
{$IFDEF PAS2JS}
    Procedure Load(Value: TJSObject);
{$ENDIF}
  End;

  TPaymentAPIKeys = Class
    PayPal: String;
    RevolutMode: String;
{$IFDEF PAS2JS}
    Procedure Load(Value: TJSObject);
    Function Revolut_Mode: String;
{$ENDIF}
  End;

  TMailingAddress = Class
    Name: String;
    Add1: String;
    Add2: String;
    Add3: String;
    City: String;
    County: String;
    PostCode: String;
    Country: String;
    Function FormatAddress(Const ALineBreak: String): String;
    Function AsHTML: String;
{$IFDEF PAS2JS}
    Procedure Load(Value: TJSObject);
{$ENDIF}
  End;

  TCustomerDocument = Class
    Location: String;
    DisplayName: String;
  End;

  TEmergencyContact = Record
    ContactName: String;
    Telephone: String;
    Relation: String;
  End;

Implementation

Uses
  System.StrUtils,
  System.Rtti,
  System.DateUtils;

{ THolidayBooking }

Procedure THolidayBooking.ClearHolidayInfo;
Begin
  HolidayId := 0;
  HolidayRef := '';
  HotelId := 0;
  VenueId := 0;
  BookingId := 0;
  HotelName := '';
  VenueName := '';
  Painters := 0;
  NonPainters := 0;
  RoomType := TRoomType.None;
  RoomType2 := TRoomType.None;
  RoomShare := True;
  GuestName := '';
  StartDate := 0;
  EndDate := 0;
  BalanceDueDate := 0;
  ChoiceComplete := False;
  WaitList := TWaitListStep.wlNoAdd;
  HighestBookingState := TBookingState.Unknown;
  TimeDataSaved := 0;
End;

Constructor THolidayBooking.Create;
Begin
  ClearHolidayInfo;
End;

Function THolidayBooking.GetLatestBookingState: TBookingState;
Begin
  Result := TRttiEnumerationType.GetValue<TBookingState>(LatestBookingState);
End;

Function THolidayBooking.GetRoom2TypeInt: Integer;
Begin
  Result := Room_Type_Int[RoomType2];
End;

Function THolidayBooking.GetRoomTypeInt: Integer;
Begin
  Result := Room_Type_Int[RoomType];
End;

Function THolidayBooking.GetWaitList: TWaitListStep;
Begin
  Result := TRttiEnumerationType.GetValue<TWaitListStep>(WaitListStep);
End;

Function THolidayBooking.GuestCount: Integer;
Begin
  Result := Painters + NonPainters;
End;

{$IFDEF PAS2JS}

Procedure THolidayBooking.Load(ABooking: TJSObject);
Begin
  HolidayId := Integer(ABooking['HolidayId']);
  HolidayRef := String(ABooking['HolidayRef']);
  HotelId := Integer(ABooking['HotelId']);
  VenueId := Integer(ABooking['VenueId']);
  BookingId := Integer(ABooking['BookingId']);
  HotelName := String(ABooking['HotelName']);
  VenueName := String(ABooking['VenueName']);
  Painters := Integer(ABooking['Painters']);
  NonPainters := Integer(ABooking['NonPainters']);
  RoomType := TRoomType(Integer(ABooking['RoomType']));
  RoomType2 := TRoomType(Integer(ABooking['RoomType2']));
  RoomShare := Boolean(ABooking['RoomShare']);
  GuestName := String(ABooking['GuestName']);
  StartDate := TDate(ABooking['StartDate']);
  EndDate := TDate(ABooking['EndDate']);
  BalanceDueDate := TDate(ABooking['BalanceDueDate']);
  ChoiceComplete := Boolean(ABooking['ChoiceComplete']);
  WaitListStep := String(ABooking['WaitListStep']);
  TimeDataSaved := TDateTime(ABooking['TimeDataSaved']);
  LatestBookingState := String(ABooking['LatestBookingState']);
End;
{$ENDIF}

Function THolidayBooking.RoomCount: Integer;
Begin
  If (GuestCount = 1) Or RoomShare Then
    Result := 1
  Else
    Result := 2;
End;

Procedure THolidayBooking.SetLatestBookingState(Const Value: TBookingState);
Begin
  LatestBookingState := TRttiEnumerationType.GetName<TBookingState>(Value);
End;

Procedure THolidayBooking.SetWaitList(Const Value: TWaitListStep);
Begin
  WaitListStep := TRttiEnumerationType.GetName<TWaitListStep>(Value);
End;

{$IFDEF PAS2JS}

Procedure THolidayVenue.Load(AVenue: TJSObject);
Begin
  VenueId := Integer(AVenue['VenueId']);
  VenueName := String(AVenue['VenueName']);
  VenueCode := String(AVenue['VenueCode']);
  IsAbroad := Boolean(AVenue['IsAbroad']);
End;

Procedure THolidayHotel.Load(AHotel: TJSObject);
Begin
  HotelId := Integer(AHotel['HotelId']);
  HotelCode := String(AHotel['HotelCode']);
  VenueId := Integer(AHotel['VenueId']);
  HotelName := String(AHotel['HotelName']);
  DogsAllowed := Boolean(AHotel['DogsAllowed']);
End;

Procedure TBankDetails.Load(Value: TJSObject);
Begin
  AccountName := String(Value['AccountName']);
  TradingName := String(Value['TradingName']);
  SortCode := String(Value['SortCode']);
  AccountNumber := String(Value['AccountNumber']);
  IBAN := String(Value['IBAN']);
  Swift := String(Value['Swift']);
  ChequeLimit := Integer(Value['ChequeLimit']);
End;

Procedure TPaymentAPIKeys.Load(Value: TJSObject);
Begin
  PayPal := String(Value['PayPal']);
  RevolutMode := String(Value['RevolutMode']);
End;

Function TPaymentAPIKeys.Revolut_Mode: String;
Begin
//  Result := RevolutMode;
  If Result = '' Then
   {$IFDEF RELEASE}
     Result := 'prod'; //'live';
   {$ELSE}
     Result := 'sandbox';
   {$ENDIF}
End;

Procedure TMailingAddress.Load(Value: TJSObject);
Begin
  Name := String(Value['Name']);
  Add1 := String(Value['Add1']);
  Add2 := String(Value['Add2']);
  Add3 := String(Value['Add3']);
  City := String(Value['City']);
  County := String(Value['County']);
  PostCode := String(Value['PostCode']);
  Country := String(Value['Country']);
End;

{ TBasicPerson }

Procedure TBasicPerson.Load(Value: TJSObject);
Begin
  CustomerId := Integer(Value['CustomerId']);
  FirstName := String(Value['FirstName']);
  LastName := String(Value['LastName']);
  Address := String(Value['Address']);
End;

{$ENDIF}
{ THolidayHotel }

Function THolidayHotel.Description: String;
Begin
  Result := HotelName;
  If DogsAllowed Then
    Result := Result + ' (dogs allowed)';
End;

{ TMailingAddress }

Function TMailingAddress.FormatAddress(Const ALineBreak: String): String;
Begin
  Result := Name;
  If Add1 <> '' Then
    Result := Result + IfThen(Result <> '', ALineBreak) + Add1;
  If Add2 <> '' Then
    Result := Result + IfThen(Result <> '', ALineBreak) + Add2;
  If Add3 <> '' Then
    Result := Result + IfThen(Result <> '', ALineBreak) + Add3;
  If City <> '' Then
    Result := Result + IfThen(Result <> '', ALineBreak) + City;
  If County <> '' Then
    Result := Result + IfThen(Result <> '', ALineBreak) + County;
  If PostCode <> '' Then
    Result := Result + IfThen(Result <> '', ALineBreak) + PostCode;
  If Country <> '' Then
    Result := Result + IfThen(Result <> '', ALineBreak) + Country;
End;

Function TMailingAddress.AsHTML: String;
Begin
  Result := FormatAddress('<br />');
End;

{ TBasicPersonList }

Procedure TBasicPersonList.AddPerson(Const ACustomerId: Integer; Const AFirstName, ALastName, AAddress: String);
Var
  APerson: TBasicPerson;
Begin
  APerson := TBasicPerson.Create;
  APerson.CustomerId := ACustomerId;
  APerson.FirstName := AFirstName;
  APerson.LastName := ALastName;
  APerson.Address := AAddress;
  Self.Add(APerson);
End;

Function TBasicPersonList.IdExists(Const Value: Integer): Boolean;
Var
  I: Integer;
Begin
  Result := False;
  For I := 0 To Count - 1 Do
  Begin
    If Self[I].CustomerId = Value Then
      Exit(True);
  End;
End;

{ TBasicPerson }

Function TBasicPerson.FullName: String;
Begin
  Result := FirstName + ' ' + LastName;
  Result := Result.Trim;
End;

End.
