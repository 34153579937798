unit AccountMyBookings;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  WebForm.Core,
  Data.DB,
  WEBLib.DB,
  WEBLib.CDS,
  DB,
  Vcl.Controls,
  WEBLib.Grids,
  WEBLib.DBCtrls,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  AccountBaseForm;

type
  TAccountMyBookingsPage = class(TCoreWebForm)
    Bookings: TClientDataSet;
    BookingsSource: TDataSource;
    BookingsBookingId: TIntegerField;
    BookingsBookingRef: TStringField;
    BookingsHolidayId: TIntegerField;
    BookingsHotelId: TIntegerField;
    BookingsVenueId: TIntegerField;
    BookingsHotelName: TStringField;
    BookingsVenueName: TStringField;
    BookingsHolidayName: TStringField;
    BookingsPainters: TIntegerField;
    BookingsNonPainters: TIntegerField;
    BookingsArrivalDate: TDateField;
    BookingsDepartureDate: TDateField;
    BookingsBalanceDueDate: TDateField;
    BookingsTotalPaid: TFloatField;
    BookingsBalanceDue: TFloatField;
    BookingsBooking_State: TStringField;
    BookingsTable: TDBTableControl;
    RefreshButton: TButton;
    BookingsDateRange: TStringField;
    BookingsGuestCount: TIntegerField;
    BookingsHolidayPrice: TFloatField;
    BookingsBalDueDate: TStringField;
    BookingsLocation: TStringField;
    CurrentDataOnly: TCheckBox;
    BookingsBookingState: TStringField;
    BookingsDepositDue: TFloatField;
    BookingsHolidayConfirmed: TBooleanField;
    procedure BookingsCalcFields(DataSet: TDataSet);
    procedure BookingsTableGetCellChildren(Sender: TObject; ACol, ARow: Integer; AField: TField; AValue: string;
      AElement: TJSHTMLElementRecord);
    procedure RefreshButtonClick(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
  private
    { Private declarations }
    FBookingId: Integer;
    FOnCloseCallback: TNotifyEvent;
    FThisPopup: TAccountBasePage;
    FAfterLoaded: TNotifyEvent;

    [Async]
    procedure LoadData; async;
    procedure AddRowActions(const ARecordId: Integer; AParentElement: TJSHTMLElement);
    procedure ViewDetails(const ABookingId: Integer);
    procedure PaySomething(const ABookingId: Integer);
    procedure GetDocuments(const ABookingId: Integer);
    [Async]
    procedure ContinueBooking(const ABookingId: Integer); async;
    [Async]
    procedure DeleteBooking(const ABookingId: Integer); async;
    [Async]
    procedure ShowThisPopUp(AFormClass: TAccountBasePageClass; const ABookingId: Integer); async;
    procedure AfterBookingLoaded(Sender: TObject);
  public
    { Public declarations }
    procedure ClosePopUp; override;
    procedure InitForm;
    property OnCloseCallback: TNotifyEvent read FOnCloseCallback write FOnCloseCallback;
     property AfterLoaded: TNotifyEvent read FAfterLoaded write FAfterLoaded;
  protected procedure LoadDFMValues; override; end;

var
  AccountMyBookingsPage: TAccountMyBookingsPage;

implementation

{$R *.dfm}

uses
  System.Rtti,
  System.StrUtils,
  System.DateUtils,
  MainDataModule,
  XData.Web.Client,
  WEBLib.WebCtrls,
  SMX.Web.Layout.Utils,
  SMX.Web.Utils,
  Cyrus.Enumerations,
  AccountBookingDetails,
  AccountBookingPayment,
  Holiday.Utils,
  Booking.Web.Shared,
  AccountDocumentsForm;

procedure TAccountMyBookingsPage.AddRowActions(const ARecordId: Integer; AParentElement: TJSHTMLElement);

  procedure ViewDetailsClick(Sender: TObject);
  begin
    ViewDetails(ARecordId);
  end;

  procedure PaymentClick(Sender: TObject);
  begin
    PaySomething(ARecordId);
  end;

  procedure DocumentsClick(Sender: TObject);
  begin
    GetDocuments(ARecordId);
  end;

  procedure ContinueClick(Sender: TObject);
  begin
    ContinueBooking(ARecordId);
  end;

  procedure DeleteClick(Sender: TObject);
  begin
    DeleteBooking(ARecordId);
  end;

var
  Span: THTMLSpan;
  lBookingState: TBookingState;
  lStateStr: string;
begin

  lStateStr := BookingsBookingState.Value;
  lBookingState := THolidayUtils.StrToBookingState(BookingsBookingState.Value);

  if (lBookingState in Pending_Booking_States) then
  begin
    Span := TLayoutUtils.RowActionSpan(AParentElement, 'fad fa-arrow-square-right', 'Continue');
    Span.OnClick := @ContinueClick;

    Span := TLayoutUtils.RowActionSpan(AParentElement, 'fad fa-trash-alt', 'Delete');
    Span.OnClick := @DeleteClick;

    Exit;
  end;

  if (lBookingState in [TBookingState.WaitingList]) then
  begin
    Span := TLayoutUtils.RowActionSpan(AParentElement, 'fad fa-trash-alt', 'Delete');
    Span.OnClick := @DeleteClick;
    Exit;
  end;

  Span := TLayoutUtils.RowActionSpan(AParentElement, 'fad fa-eye', 'Details');
  Span.OnClick := @ViewDetailsClick;

  if (BookingsBalanceDue.Value > 0)  then
  begin
    if BookingsHolidayConfirmed.Value or (BookingsTotalPaid.Value = 0) then
    begin
       Span := TLayoutUtils.RowActionSpan(AParentElement, 'fad fa-credit-card', 'Pay');
       Span.OnClick := @PaymentClick;
    end;
  end;

//  Span := TLayoutUtils.RowActionSpan(AParentElement, 'fad fa-copy', 'Documents');
//  Span.OnClick := @DocumentsClick;

{$IFDEF DEBUG}
  Span := TLayoutUtils.RowActionSpan(AParentElement, 'fad fa-trash-alt', 'Delete');
  Span.OnClick := @DeleteClick;
{$ENDIF}
end;

procedure TAccountMyBookingsPage.AfterBookingLoaded(Sender: TObject);
begin
  MainData.OnSelectedBookingLoaded := nil;
  MainData.ContinueBooking(FBookingId);
  FBookingId := 0;
end;

procedure TAccountMyBookingsPage.BookingsCalcFields(DataSet: TDataSet);
var
  lState: TBookingState;
begin

  lState := THolidayUtils.StrToBookingState(BookingsBookingState.Value);

  case lState of
    TBookingState.Unknown .. TBookingState.OptionalExtrasComplete:BookingsBooking_State.Value := 'Pending'
      {$IFDEF DEBUG} + ': ' + TWebUtils.SplitOnCaps(BookingsBookingState.Value){$ENDIF};
    TBookingState.NotChangeable .. TBookingState.Confirmed:
      BookingsBooking_State.Value := 'Provisional'
      {$IFDEF DEBUG} + ': ' + TWebUtils.SplitOnCaps(BookingsBookingState.Value){$ENDIF};
  else
    BookingsBooking_State.Value := TUtils.SplitOnCaps(BookingsBookingState.Value);
  end;

  BookingsGuestCount.Value := BookingsPainters.Value + BookingsNonPainters.Value;
  if BookingsBalanceDue.Value > 0 then
    BookingsBalDueDate.Value := FormatDateTime('dd/mm/yyyy', BookingsBalanceDueDate.Value);
  BookingsLocation.Value := BookingsHotelName.Value + ',<br>' + BookingsVenueName.Value;
end;

procedure TAccountMyBookingsPage.BookingsTableGetCellChildren(Sender: TObject; ACol, ARow: Integer; AField: TField;
  AValue: string; AElement: TJSHTMLElementRecord);
begin
  if ARow = 0 then
    Exit;

  if BookingsTable.Columns[ACol].Title = 'Actions' then
  begin
    AddRowActions(BookingsBookingId.Value, AElement.Element);
  end;
end;

procedure TAccountMyBookingsPage.ClosePopUp;
begin
  inherited;
  if Assigned(FThisPopup) then
  begin
    FThisPopup.TearDown;
    FThisPopup.Free;
    FThisPopup := nil;
    LoadData;
  end;
end;

procedure TAccountMyBookingsPage.ContinueBooking(const ABookingId: Integer);
var
  lBookingState: TBookingState;
  Retval: Boolean;
begin
  FBookingId := ABookingId;

  Bookings.Locate('BookingId', ABookingId, []);
  lBookingState := THolidayUtils.StrToBookingState(BookingsBookingState.Value);
  if lBookingState > Latest_Change_State then
  begin
    DisplayMessage('Sorry, you are not able to change this here. Please contact Alpha Painting Holidays');
    Exit;
  end;

  if BookingsArrivalDate.Value <= Today then
  begin
    DisplayMessage('Sorry, you can no longer change these details. Please contact Alpha Painting Holidays');
    Exit;
  end;

  Retval := Await(MainData.HolidayStillAvailable(BookingsHolidayId.Value));
  if not Retval then
  begin
    DisplayMessage('Sorry, this holiday is no longer available. Please contact Alpha Painting Holidays');
    Exit;
  end;

  if FBookingId = MainData.BookingId then
    MainData.SwitchMainPage(TMainAppPage.mpBooking)
  else
  begin
    MainData.OnSelectedBookingLoaded := AfterBookingLoaded;
    MainData.LoadSelectedBooking(ABookingId);
  end;
end;

procedure TAccountMyBookingsPage.DeleteBooking(const ABookingId: Integer);
const
  Account_Delete_Booking = 'ICustomerService.DeleteBooking';
var
  lRetval: TModalResult;
  lMessage: string;
  lBookingState: TBookingState;
  // lRetval: TXDataClientResponse;
begin
  if not Bookings.Locate('BookingId', ABookingId, []) then
  begin

    Exit;
  end;

  lBookingState := THolidayUtils.StrToBookingState(BookingsBookingState.Value);
  lMessage := '';

  if (lBookingState in Pending_Booking_States) then
    lMessage := 'this incomplete booking - '
  else if (lBookingState = TBookingState.WaitingList) then
    lMessage := 'yourself from the waiting list for '
  else
  begin
{$IFDEF DEBUG}
    lMessage := ' this booking (debug only for this state) ';
{$ELSE}
    raise Exception.Create('You cannot delete this booking');
{$ENDIF}
  end;

  lMessage := 'Are you sure you want to remove ' + lMessage + BookingsHolidayName.Value + ' ' +
    BookingsDateRange.Value + '?';
  lRetval := Await(ConfirmationRequest(lMessage, TMsgDlgType.mtConfirmation, [mbYes, mbNo]));

  if lRetval = mrYes then
  begin
    if MainData.BookingId = ABookingId then
       MainData.CloseBookingData(True);

    Await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync(Account_Delete_Booking, [ABookingId]));

    LoadData;

    if Assigned(FOnCloseCallback) then
       FOnCloseCallback(Self);
  end;

end;

procedure TAccountMyBookingsPage.GetDocuments(const ABookingId: Integer);
begin
  ShowThisPopUp(TAccountDocumentsPage, ABookingId);
end;

procedure TAccountMyBookingsPage.InitForm;
begin
  CurrentDataOnly.Visible := False;
  LoadData;
end;

procedure TAccountMyBookingsPage.WebFormCreate(Sender: TObject);
begin
  inherited;
  window.location.hash := PageHashName;
{$IFDEF RELEASE}
//  BookingsTable.HideColumn(0);
{$ENDIF}
end;

procedure TAccountMyBookingsPage.LoadData;
const
  Account_Bookings_Svc = 'ICustomerService.MyBookings';
var
  lRetval: TXDataClientResponse;
  lData: JS.TJSArray;
begin
  Bookings.DisableControls;
  try
    Bookings.Active := False;

    lRetval := Await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync(Account_Bookings_Svc,
      [CurrentDataOnly.Checked]));
    lData := TJSArray(lRetval.ResultAsObject['value']);

    Bookings.Rows := lData;
    Bookings.Active := True;

  finally
    Bookings.EnableControls;
  end;

  if Assigned(FAfterLoaded) then
     FAfterLoaded(Self);

end;

procedure TAccountMyBookingsPage.PaySomething(const ABookingId: Integer);
begin
  ShowThisPopUp(TAccountBookingPaymentPage, ABookingId);
end;

procedure TAccountMyBookingsPage.RefreshButtonClick(Sender: TObject);
begin
  LoadData;
end;

procedure TAccountMyBookingsPage.ShowThisPopUp(AFormClass: TAccountBasePageClass; const ABookingId: Integer);
var
  lClass: string;
begin
  FThisPopup := AFormClass.Create(Self);
  try
    FThisPopup.Popup := True;
    FThisPopup.ElementClassName := 'PopUpForm PopUp';
    FThisPopup.PopupOpacity := 1;
    Await(TForm, FThisPopup.Load());
    FThisPopup.OnCloseCallback := FOnCloseCallBack;
    FThisPopup.BookingId := ABookingId;
    Await(TModalResult, FThisPopup.Execute);
  finally
    FThisPopup.TearDown;
    FThisPopup.Free;
    FThisPopup := nil;
    LoadData;
  end;

end;

procedure TAccountMyBookingsPage.ViewDetails(const ABookingId: Integer);
begin
  ShowThisPopUp(TAccountBookingDetailsPage, ABookingId);
end;

procedure TAccountMyBookingsPage.LoadDFMValues;
begin
  inherited LoadDFMValues;

  BookingsTable := TDBTableControl.Create('BookingsTable');
  RefreshButton := TButton.Create('RefreshButton');
  CurrentDataOnly := TCheckBox.Create('CurrentDataOnly');
  Bookings := TClientDataSet.Create(Self);
  BookingsBookingId := TIntegerField.Create(Self);
  BookingsBookingRef := TStringField.Create(Self);
  BookingsBookingState := TStringField.Create(Self);
  BookingsHolidayId := TIntegerField.Create(Self);
  BookingsHotelId := TIntegerField.Create(Self);
  BookingsVenueId := TIntegerField.Create(Self);
  BookingsHotelName := TStringField.Create(Self);
  BookingsVenueName := TStringField.Create(Self);
  BookingsHolidayName := TStringField.Create(Self);
  BookingsPainters := TIntegerField.Create(Self);
  BookingsNonPainters := TIntegerField.Create(Self);
  BookingsArrivalDate := TDateField.Create(Self);
  BookingsDepartureDate := TDateField.Create(Self);
  BookingsDateRange := TStringField.Create(Self);
  BookingsBalanceDueDate := TDateField.Create(Self);
  BookingsHolidayPrice := TFloatField.Create(Self);
  BookingsTotalPaid := TFloatField.Create(Self);
  BookingsDepositDue := TFloatField.Create(Self);
  BookingsBalanceDue := TFloatField.Create(Self);
  BookingsBooking_State := TStringField.Create(Self);
  BookingsHolidayConfirmed := TBooleanField.Create(Self);
  BookingsGuestCount := TIntegerField.Create(Self);
  BookingsBalDueDate := TStringField.Create(Self);
  BookingsLocation := TStringField.Create(Self);
  BookingsSource := TDataSource.Create(Self);

  BookingsTable.BeforeLoadDFMValues;
  RefreshButton.BeforeLoadDFMValues;
  CurrentDataOnly.BeforeLoadDFMValues;
  Bookings.BeforeLoadDFMValues;
  BookingsBookingId.BeforeLoadDFMValues;
  BookingsBookingRef.BeforeLoadDFMValues;
  BookingsBookingState.BeforeLoadDFMValues;
  BookingsHolidayId.BeforeLoadDFMValues;
  BookingsHotelId.BeforeLoadDFMValues;
  BookingsVenueId.BeforeLoadDFMValues;
  BookingsHotelName.BeforeLoadDFMValues;
  BookingsVenueName.BeforeLoadDFMValues;
  BookingsHolidayName.BeforeLoadDFMValues;
  BookingsPainters.BeforeLoadDFMValues;
  BookingsNonPainters.BeforeLoadDFMValues;
  BookingsArrivalDate.BeforeLoadDFMValues;
  BookingsDepartureDate.BeforeLoadDFMValues;
  BookingsDateRange.BeforeLoadDFMValues;
  BookingsBalanceDueDate.BeforeLoadDFMValues;
  BookingsHolidayPrice.BeforeLoadDFMValues;
  BookingsTotalPaid.BeforeLoadDFMValues;
  BookingsDepositDue.BeforeLoadDFMValues;
  BookingsBalanceDue.BeforeLoadDFMValues;
  BookingsBooking_State.BeforeLoadDFMValues;
  BookingsHolidayConfirmed.BeforeLoadDFMValues;
  BookingsGuestCount.BeforeLoadDFMValues;
  BookingsBalDueDate.BeforeLoadDFMValues;
  BookingsLocation.BeforeLoadDFMValues;
  BookingsSource.BeforeLoadDFMValues;
  try
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    BookingsTable.SetParentComponent(Self);
    BookingsTable.Name := 'BookingsTable';
    BookingsTable.Left := 152;
    BookingsTable.Top := 96;
    BookingsTable.Width := 300;
    BookingsTable.Height := 200;
    BookingsTable.HeightStyle := ssAuto;
    BookingsTable.WidthStyle := ssAuto;
    BookingsTable.BorderColor := clSilver;
    BookingsTable.ElementFont := efCSS;
    BookingsTable.ElementPosition := epIgnore;
    BookingsTable.Options.AutoNumAlign := True;
    SetEvent(BookingsTable, Self, 'OnGetCellChildren', 'BookingsTableGetCellChildren');
    BookingsTable.Columns.Clear;
    with BookingsTable.Columns.Add do
    begin
      DataField := 'BookingId';
      Title := 'Id';
    end;
    with BookingsTable.Columns.Add do
    begin
      DataField := 'BookingRef';
      Title := 'Reference';
    end;
    with BookingsTable.Columns.Add do
    begin
      DataField := 'Booking_State';
      Title := 'Status';
    end;
    with BookingsTable.Columns.Add do
    begin
      DataField := 'Location';
      Title := 'Hotel/Destination';
    end;
    with BookingsTable.Columns.Add do
    begin
      DataField := 'HolidayName';
      Title := 'Holiday';
    end;
    with BookingsTable.Columns.Add do
    begin
      DataField := 'DateRange';
      Title := 'Arrive/Depart';
    end;
    with BookingsTable.Columns.Add do
    begin
      DataField := 'HolidayPrice';
      Title := 'Total<br>Price';
    end;
    with BookingsTable.Columns.Add do
    begin
      DataField := 'TotalPaid';
      Title := 'Paid';
    end;
    with BookingsTable.Columns.Add do
    begin
      DataField := 'BalanceDue';
      Title := 'Balance';
    end;
    with BookingsTable.Columns.Add do
    begin
      DataField := 'BalDueDate';
      Title := 'Balance<br>Due';
    end;
    with BookingsTable.Columns.Add do
    begin
      Title := 'Actions';
    end;
    BookingsTable.DataSource := BookingsSource;
    RefreshButton.SetParentComponent(Self);
    RefreshButton.Name := 'RefreshButton';
    RefreshButton.Left := 208;
    RefreshButton.Top := 48;
    RefreshButton.Width := 96;
    RefreshButton.Height := 25;
    RefreshButton.Caption := 'Refresh';
    RefreshButton.ChildOrder := 2;
    RefreshButton.ElementClassName := 'btn btn-primary';
    RefreshButton.ElementFont := efCSS;
    RefreshButton.ElementPosition := epIgnore;
    RefreshButton.HeightStyle := ssAuto;
    RefreshButton.HeightPercent := 100.000000000000000000;
    RefreshButton.WidthStyle := ssAuto;
    RefreshButton.WidthPercent := 100.000000000000000000;
    SetEvent(RefreshButton, Self, 'OnClick', 'RefreshButtonClick');
    CurrentDataOnly.SetParentComponent(Self);
    CurrentDataOnly.Name := 'CurrentDataOnly';
    CurrentDataOnly.Left := 344;
    CurrentDataOnly.Top := 48;
    CurrentDataOnly.Width := 113;
    CurrentDataOnly.Height := 22;
    CurrentDataOnly.Checked := True;
    CurrentDataOnly.ChildOrder := 2;
    CurrentDataOnly.ElementClassName := ' form-check-input';
    CurrentDataOnly.ElementFont := efCSS;
    CurrentDataOnly.ElementPosition := epIgnore;
    CurrentDataOnly.HeightStyle := ssAuto;
    CurrentDataOnly.HeightPercent := 100.000000000000000000;
    CurrentDataOnly.State := cbChecked;
    CurrentDataOnly.WidthStyle := ssAuto;
    CurrentDataOnly.WidthPercent := 100.000000000000000000;
    Bookings.SetParentComponent(Self);
    Bookings.Name := 'Bookings';
    SetEvent(Bookings, Self, 'OnCalcFields', 'BookingsCalcFields');
    Bookings.Left := 80;
    Bookings.Top := 56;
    BookingsBookingId.SetParentComponent(Bookings);
    BookingsBookingId.Name := 'BookingsBookingId';
    BookingsBookingId.FieldName := 'BookingId';
    BookingsBookingRef.SetParentComponent(Bookings);
    BookingsBookingRef.Name := 'BookingsBookingRef';
    BookingsBookingRef.FieldName := 'BookingRef';
    BookingsBookingRef.Size := 50;
    BookingsBookingState.SetParentComponent(Bookings);
    BookingsBookingState.Name := 'BookingsBookingState';
    BookingsBookingState.FieldName := 'BookingState';
    BookingsBookingState.Size := 30;
    BookingsHolidayId.SetParentComponent(Bookings);
    BookingsHolidayId.Name := 'BookingsHolidayId';
    BookingsHolidayId.FieldName := 'HolidayId';
    BookingsHotelId.SetParentComponent(Bookings);
    BookingsHotelId.Name := 'BookingsHotelId';
    BookingsHotelId.FieldName := 'HotelId';
    BookingsVenueId.SetParentComponent(Bookings);
    BookingsVenueId.Name := 'BookingsVenueId';
    BookingsVenueId.FieldName := 'VenueId';
    BookingsHotelName.SetParentComponent(Bookings);
    BookingsHotelName.Name := 'BookingsHotelName';
    BookingsHotelName.FieldName := 'HotelName';
    BookingsHotelName.Size := 255;
    BookingsVenueName.SetParentComponent(Bookings);
    BookingsVenueName.Name := 'BookingsVenueName';
    BookingsVenueName.FieldName := 'VenueName';
    BookingsVenueName.Size := 255;
    BookingsHolidayName.SetParentComponent(Bookings);
    BookingsHolidayName.Name := 'BookingsHolidayName';
    BookingsHolidayName.FieldName := 'HolidayName';
    BookingsHolidayName.Size := 255;
    BookingsPainters.SetParentComponent(Bookings);
    BookingsPainters.Name := 'BookingsPainters';
    BookingsPainters.FieldName := 'Painters';
    BookingsNonPainters.SetParentComponent(Bookings);
    BookingsNonPainters.Name := 'BookingsNonPainters';
    BookingsNonPainters.FieldName := 'NonPainters';
    BookingsArrivalDate.SetParentComponent(Bookings);
    BookingsArrivalDate.Name := 'BookingsArrivalDate';
    BookingsArrivalDate.FieldName := 'ArrivalDate';
    BookingsDepartureDate.SetParentComponent(Bookings);
    BookingsDepartureDate.Name := 'BookingsDepartureDate';
    BookingsDepartureDate.FieldName := 'DepartureDate';
    BookingsDateRange.SetParentComponent(Bookings);
    BookingsDateRange.Name := 'BookingsDateRange';
    BookingsDateRange.FieldName := 'DateRange';
    BookingsDateRange.Size := 50;
    BookingsBalanceDueDate.SetParentComponent(Bookings);
    BookingsBalanceDueDate.Name := 'BookingsBalanceDueDate';
    BookingsBalanceDueDate.FieldName := 'BalanceDueDate';
    BookingsHolidayPrice.SetParentComponent(Bookings);
    BookingsHolidayPrice.Name := 'BookingsHolidayPrice';
    BookingsHolidayPrice.FieldName := 'HolidayPrice';
    BookingsHolidayPrice.DisplayFormat := #163'#,##0.00';
    BookingsTotalPaid.SetParentComponent(Bookings);
    BookingsTotalPaid.Name := 'BookingsTotalPaid';
    BookingsTotalPaid.FieldName := 'TotalPaid';
    BookingsTotalPaid.DisplayFormat := #163'#,##0.00';
    BookingsDepositDue.SetParentComponent(Bookings);
    BookingsDepositDue.Name := 'BookingsDepositDue';
    BookingsDepositDue.FieldName := 'DepositDue';
    BookingsBalanceDue.SetParentComponent(Bookings);
    BookingsBalanceDue.Name := 'BookingsBalanceDue';
    BookingsBalanceDue.FieldName := 'BalanceDue';
    BookingsBalanceDue.DisplayFormat := #163'#,##0.00';
    BookingsBooking_State.SetParentComponent(Bookings);
    BookingsBooking_State.Name := 'BookingsBooking_State';
    BookingsBooking_State.FieldKind := fkCalculated;
    BookingsBooking_State.FieldName := 'Booking_State';
    BookingsBooking_State.Size := 25;
    BookingsBooking_State.Calculated := True;
    BookingsHolidayConfirmed.SetParentComponent(Bookings);
    BookingsHolidayConfirmed.Name := 'BookingsHolidayConfirmed';
    BookingsHolidayConfirmed.FieldName := 'HolidayConfirmed';
    BookingsGuestCount.SetParentComponent(Bookings);
    BookingsGuestCount.Name := 'BookingsGuestCount';
    BookingsGuestCount.FieldKind := fkCalculated;
    BookingsGuestCount.FieldName := 'GuestCount';
    BookingsGuestCount.Calculated := True;
    BookingsBalDueDate.SetParentComponent(Bookings);
    BookingsBalDueDate.Name := 'BookingsBalDueDate';
    BookingsBalDueDate.FieldKind := fkCalculated;
    BookingsBalDueDate.FieldName := 'BalDueDate';
    BookingsBalDueDate.Size := 25;
    BookingsBalDueDate.Calculated := True;
    BookingsLocation.SetParentComponent(Bookings);
    BookingsLocation.Name := 'BookingsLocation';
    BookingsLocation.FieldKind := fkCalculated;
    BookingsLocation.FieldName := 'Location';
    BookingsLocation.Size := 255;
    BookingsLocation.Calculated := True;
    BookingsSource.SetParentComponent(Self);
    BookingsSource.Name := 'BookingsSource';
    BookingsSource.DataSet := Bookings;
    BookingsSource.Left := 80;
    BookingsSource.Top := 128;
  finally
    BookingsTable.AfterLoadDFMValues;
    RefreshButton.AfterLoadDFMValues;
    CurrentDataOnly.AfterLoadDFMValues;
    Bookings.AfterLoadDFMValues;
    BookingsBookingId.AfterLoadDFMValues;
    BookingsBookingRef.AfterLoadDFMValues;
    BookingsBookingState.AfterLoadDFMValues;
    BookingsHolidayId.AfterLoadDFMValues;
    BookingsHotelId.AfterLoadDFMValues;
    BookingsVenueId.AfterLoadDFMValues;
    BookingsHotelName.AfterLoadDFMValues;
    BookingsVenueName.AfterLoadDFMValues;
    BookingsHolidayName.AfterLoadDFMValues;
    BookingsPainters.AfterLoadDFMValues;
    BookingsNonPainters.AfterLoadDFMValues;
    BookingsArrivalDate.AfterLoadDFMValues;
    BookingsDepartureDate.AfterLoadDFMValues;
    BookingsDateRange.AfterLoadDFMValues;
    BookingsBalanceDueDate.AfterLoadDFMValues;
    BookingsHolidayPrice.AfterLoadDFMValues;
    BookingsTotalPaid.AfterLoadDFMValues;
    BookingsDepositDue.AfterLoadDFMValues;
    BookingsBalanceDue.AfterLoadDFMValues;
    BookingsBooking_State.AfterLoadDFMValues;
    BookingsHolidayConfirmed.AfterLoadDFMValues;
    BookingsGuestCount.AfterLoadDFMValues;
    BookingsBalDueDate.AfterLoadDFMValues;
    BookingsLocation.AfterLoadDFMValues;
    BookingsSource.AfterLoadDFMValues;
  end;
end;

end.
