Unit AccountMainForm;

Interface

Uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  MainForm.Base,
  WEBLib.Toast,
  Vcl.Controls,
  WEBLib.Grids,
  WEBLib.DBCtrls,
  Vcl.StdCtrls,
  WEBLib.StdCtrls;

Type
  TAccountMainPage = Class(TMainBasePage)
    WebDBTableControl1: TDBTableControl;
    NewBookingLink: TLabel;
    SignOutLink: TLabel;
    MyDetailsLink: TLabel;
    Procedure MyDetailsLinkClick(Sender: TObject);
    Procedure NewBookingLinkClick(Sender: TObject);
    Procedure SignOutLinkClick(Sender: TObject);
    Procedure WebFormBeforeUnload(Sender: TObject; Var AMessage: String);
    Procedure WebFormCreate(Sender: TObject);
  Private
    { Private declarations }
    FFirstLoad: Boolean;
    Procedure OnUpdateForm(Sender: TObject);
    Procedure AfterLoadForm(Sender: TObject);
    [async]
    Procedure ShowMyBookings; async;
    [async]
    Procedure EditMyDetails; async;
  Protected
    [async]
    Procedure InitForm; async; Override;
    Procedure HandlePopState(State: TJSObject; Const AURL, AHash: String); Override;
  Public
    { Public declarations }
    Class Procedure DisplayPage(Const AElementId: String); Override;
  protected procedure LoadDFMValues; override; End;

Var
  AccountMainPage: TAccountMainPage;

Implementation

{$R *.dfm}

Uses
  smx.webcore.Types,
  WebForm.Core,
  MainDataModule,
  AccountMyBookings,
  Booking.Web.Shared,
  AccountBaseForm,
  AccountCustomerDetails;

Procedure TAccountMainPage.WebFormCreate(Sender: TObject);
Begin
  Inherited;
  FFirstLoad := True;
  ShowMyBookings;
End;

{ TMainAccountPage }

Procedure TAccountMainPage.AfterLoadForm(Sender: TObject);
Begin
  If FFirstLoad Then
  Begin
    FFirstLoad := False;
    If MainData.IsFirstRegistration And (Not MainData.CustomerDetailsUpdated) Then
      EditMyDetails;
  End;
End;

Class Procedure TAccountMainPage.DisplayPage(Const AElementId: String);
  Procedure AfterCreate(AForm: TObject);
  Begin
    // TMainPage(AForm).SetLabels;
  End;

Begin
  If Not Assigned(AccountMainPage) Then
  Begin
    AccountMainPage := TAccountMainPage.CreateNew(AElementId, @AfterCreate);
  End;
End;

Procedure TAccountMainPage.EditMyDetails;
Var
  Retval: TModalResult;
Begin
  Retval := await(ShowPopUp(TMyDetails));
  If Retval = mrOK Then
    MainData.ReloadCustomer;
End;

Procedure TAccountMainPage.HandlePopState(State: TJSObject; Const AURL, AHash: String);
Var
  lHash: String;
Begin
  If AHash.StartsWith('Account') Then
  Begin
    If (FDisplayedForm Is TCoreWebForm) Then
    Begin
      lHash := TCoreWebForm(FDisplayedForm).PageHashName;
      If lHash = AHash Then
      Begin
        TCoreWebForm(FDisplayedForm).ClosePopUp;
        ClosePopUp;
      End;
    End;
  End
  Else If AHash.StartsWith('bp') Then
    MainData.SwitchMainPage(TMainAppPage.mpBooking)
End;

Procedure TAccountMainPage.InitForm;
Begin
  Inherited;
  Caption := Application_Title;
  MainData.ErrorMessageProc := ErrorHandler;
  OnUpdateForm(Self);
End;

Procedure TAccountMainPage.MyDetailsLinkClick(Sender: TObject);
Begin
  EditMyDetails;
End;

Procedure TAccountMainPage.NewBookingLinkClick(Sender: TObject);
Begin
  MainData.SwitchMainPage(TMainAppPage.mpBooking);
End;

Procedure TAccountMainPage.OnUpdateForm(Sender: TObject);
Begin
  If MainData.IsActiveBooking Then
    NewBookingLink.Caption := 'Continue booking'
  Else
    NewBookingLink.Caption := 'Make a new booking';
End;

Procedure TAccountMainPage.ShowMyBookings;

  Procedure AfterFormCreated(AForm: TObject);
  Begin

    TCoreWebForm(AForm).OnSelfClose := OnFormSelfClose;
    TAccountMyBookingsPage(AForm).OnCloseCallback := OnUpdateForm;
    TAccountMyBookingsPage(AForm).AfterLoaded := AfterLoadForm;
    TCoreWebForm(AForm).AfterCreated;
    TAccountMyBookingsPage(AForm).InitForm;

    HideWaitMessage;
  End;

Begin
  If Assigned(FDisplayedForm) And (FDisplayedForm.ClassType = TAccountMyBookingsPage) Then
    Exit;

  If Not CloseDisplayedForm(True) Then
    Exit;

  Application.CreateForm(TAccountMyBookingsPage, 'MainPanel', FDisplayedForm, @AfterFormCreated);

End;

Procedure TAccountMainPage.SignOutLinkClick(Sender: TObject);
Begin
  MainData.Logout(TLogoutMethod.lomManual);

End;

Procedure TAccountMainPage.WebFormBeforeUnload(Sender: TObject; Var AMessage: String);
Begin
  Inherited;
  CloseDisplayedForm;
  MainData.Logout(TLogoutMethod.lomBrowserClose);
  MainData.SwitchMainPage(TMainAppPage.mpBooking);
End;

procedure TAccountMainPage.LoadDFMValues;
begin
  inherited LoadDFMValues;

  NewBookingLink := TLabel.Create('NewBookingLink');
  SignOutLink := TLabel.Create('SignOutLink');
  MyDetailsLink := TLabel.Create('MyDetailsLink');
  WebDBTableControl1 := TDBTableControl.Create(Self);

  NewBookingLink.BeforeLoadDFMValues;
  SignOutLink.BeforeLoadDFMValues;
  MyDetailsLink.BeforeLoadDFMValues;
  WebDBTableControl1.BeforeLoadDFMValues;
  try
    NewBookingLink.SetParentComponent(Self);
    NewBookingLink.Name := 'NewBookingLink';
    NewBookingLink.Left := 32;
    NewBookingLink.Top := 200;
    NewBookingLink.Width := 136;
    NewBookingLink.Height := 18;
    NewBookingLink.Caption := 'Make a New Booking';
    NewBookingLink.ElementClassName := 'btn btn-primary';
    NewBookingLink.ElementFont := efCSS;
    NewBookingLink.ElementPosition := epIgnore;
    NewBookingLink.HeightStyle := ssAuto;
    NewBookingLink.HeightPercent := 100.000000000000000000;
    NewBookingLink.WidthStyle := ssAuto;
    NewBookingLink.WidthPercent := 100.000000000000000000;
    SetEvent(NewBookingLink, Self, 'OnClick', 'NewBookingLinkClick');
    SignOutLink.SetParentComponent(Self);
    SignOutLink.Name := 'SignOutLink';
    SignOutLink.Left := 32;
    SignOutLink.Top := 232;
    SignOutLink.Width := 55;
    SignOutLink.Height := 18;
    SignOutLink.Caption := 'Sign Out';
    SignOutLink.ElementClassName := 'btn btn-primary';
    SignOutLink.ElementFont := efCSS;
    SignOutLink.ElementPosition := epIgnore;
    SignOutLink.HeightStyle := ssAuto;
    SignOutLink.HeightPercent := 100.000000000000000000;
    SignOutLink.WidthPercent := 100.000000000000000000;
    SetEvent(SignOutLink, Self, 'OnClick', 'SignOutLinkClick');
    MyDetailsLink.SetParentComponent(Self);
    MyDetailsLink.Name := 'MyDetailsLink';
    MyDetailsLink.Left := 32;
    MyDetailsLink.Top := 272;
    MyDetailsLink.Width := 118;
    MyDetailsLink.Height := 18;
    MyDetailsLink.Caption := 'Update my details';
    MyDetailsLink.ElementClassName := 'btn btn-primary';
    MyDetailsLink.ElementFont := efCSS;
    MyDetailsLink.ElementPosition := epIgnore;
    MyDetailsLink.HeightStyle := ssAuto;
    MyDetailsLink.HeightPercent := 100.000000000000000000;
    MyDetailsLink.WidthStyle := ssAuto;
    MyDetailsLink.WidthPercent := 100.000000000000000000;
    SetEvent(MyDetailsLink, Self, 'OnClick', 'MyDetailsLinkClick');
    WebDBTableControl1.SetParentComponent(Self);
    WebDBTableControl1.Name := 'WebDBTableControl1';
    WebDBTableControl1.Left := 197;
    WebDBTableControl1.Top := 106;
    WebDBTableControl1.Width := 300;
    WebDBTableControl1.Height := 200;
    WebDBTableControl1.HeightStyle := ssAuto;
    WebDBTableControl1.WidthStyle := ssAuto;
    WebDBTableControl1.BorderColor := clSilver;
    WebDBTableControl1.ChildOrder := 2;
    WebDBTableControl1.ElementFont := efCSS;
    WebDBTableControl1.ElementHeaderClassName := 'thead-light';
    WebDBTableControl1.ElementPosition := epIgnore;
    WebDBTableControl1.ElementTableClassName := 'table table-striped table-bordered  table-hover';
    WebDBTableControl1.Footer.ButtonActiveElementClassName := 'btn btn-primary';
    WebDBTableControl1.Footer.ButtonElementClassName := 'btn btn-light';
    WebDBTableControl1.Footer.DropDownElementClassName := 'form-control';
    WebDBTableControl1.Footer.InputElementClassName := 'form-control';
    WebDBTableControl1.Footer.LinkActiveElementClassName := 'link-primary';
    WebDBTableControl1.Footer.LinkElementClassName := 'link-secondary';
    WebDBTableControl1.Footer.ListElementClassName := 'pagination';
    WebDBTableControl1.Footer.ListItemElementClassName := 'page-item';
    WebDBTableControl1.Footer.ListLinkElementClassName := 'page-link';
    WebDBTableControl1.Header.ButtonActiveElementClassName := 'btn btn-primary';
    WebDBTableControl1.Header.ButtonElementClassName := 'btn btn-light';
    WebDBTableControl1.Header.DropDownElementClassName := 'form-control';
    WebDBTableControl1.Header.InputElementClassName := 'form-control';
    WebDBTableControl1.Header.LinkActiveElementClassName := 'link-primary';
    WebDBTableControl1.Header.LinkElementClassName := 'link-secondary';
    WebDBTableControl1.Header.ListElementClassName := 'pagination';
    WebDBTableControl1.Header.ListItemElementClassName := 'page-item';
    WebDBTableControl1.Header.ListLinkElementClassName := 'page-link';
  finally
    NewBookingLink.AfterLoadDFMValues;
    SignOutLink.AfterLoadDFMValues;
    MyDetailsLink.AfterLoadDFMValues;
    WebDBTableControl1.AfterLoadDFMValues;
  end;
end;

End.
