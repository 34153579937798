Unit MainBookingForm;

Interface

Uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  Vcl.Controls,
  WEBLib.Toast,
  App.Types,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  WEBLib.WebCtrls,
  WEBLib.Lists,
  MainForm.Base,
  Booking.Web.Shared,
  HolidayPayments,
  Cyrus.Enumerations,
  WEBLib.ExtCtrls;

Type
  TMainBookingPage = Class(TMainBasePage)
    PreviousButton: TButton;
    NextButton: TButton;
    Crumbline: TListControl;
    DebugPanel: TPanel;
    MyAccountLink: TLabel;
    SignOutLink: TLabel;
    LoggedInUserSpan: THTMLSpan;
    Procedure MyAccountLinkClick(Sender: TObject);
    Procedure NextButtonClick(Sender: TObject);
    Procedure PreviousButtonClick(Sender: TObject);
    Procedure SignOutLinkClick(Sender: TObject);
    Procedure WebFormBeforeUnload(Sender: TObject; Var AMessage: String);
  Private
    { Private declarations }
    FDisplayedPage: TBookingPage;
    FWaitDirection: TNavDirection;
    Procedure OnFormSelfClose(Sender: TObject); Override;
    [async]
    Procedure HandleNextClick; async;
    Procedure HandlePreviousClick;
    Procedure OnReadyToProgress(Const CanProgress: Boolean);
    Procedure AfterLogout(Sender: TObject);
    [async]
    Procedure ShowForm(AFormClass: TFormClass; Const ADisplayedPage: TBookingPage;
      Const ANavDirection: TNavDirection; AProc: TFormCreatedProc = Nil; AlwaysClose: Boolean = false); async; Reintroduce;
    Function CloseDisplayedForm(Const SkipValidation: Boolean = True): Boolean;
    Procedure HandleBookingExists;
    Procedure HandleWaitList;
    [async]
    Procedure BookingPageUpdate(Const ThePreviousButton, TheNextButton: TButtonState; Const CrumblineIndex: Integer;
      Const HideMyAccount: Boolean); async;
    Procedure ChangeThePage(Const ANewPage: TBookingPage; Const ANavDirection: TNavDirection);
    Procedure UpdateLoggedInSpan;
  Protected
    [async]
    Procedure InitForm; async; Override;
    Procedure HashChange(Sender: TObject; AHash: String; Var Handled: Boolean);
    Procedure HandlePopState(State: TJSObject; Const AURL, AHash: String); Override;
  Public

    { Public declarations }
    Class Procedure DisplayPage(Const AElementId: String); Override;
    Procedure CloseMainPage; Override;
    Procedure ShowConfirmationPage;
  protected procedure LoadDFMValues; override; End;

Var
  MainBookingPage: TMainBookingPage;

Implementation

{$R *.dfm}

Uses
  System.Rtti,
  WebForm.Core,
  HolidayChoice,
  BaseBooking,
  CustomerDetails,
  BookingRequirements,
  OptionalExtras,
  Confirmation,
  MainDataModule,
  ErrorForm,
  Holiday.ReturnTypes;

Procedure TMainBookingPage.WebFormBeforeUnload(Sender: TObject; Var AMessage: String);
Begin
  Inherited;
  If MainData.ProcessingState = TProcessingState.psLoggingIn Then
    Exit;
  CloseDisplayedForm(True);
  MainData.Logout(TLogoutMethod.lomBrowserClose);
End;

Procedure TMainBookingPage.AfterLogout(Sender: TObject);
Begin
  If FDisplayedPage <> TBookingPage.bpConfirmation Then
    ChangeThePage(TBookingPage.bpChooseHoliday, TNavDirection.ndNone);

End;

Procedure TMainBookingPage.BookingPageUpdate(Const ThePreviousButton, TheNextButton: TButtonState;
  Const CrumblineIndex: Integer; Const HideMyAccount: Boolean);
Var
  I: Integer;
Begin
  If Not Assigned(MainBookingPage) Then
    Exit;

  Case ThePreviousButton Of
    wbsEnabled:
      Begin
        PreviousButton.Enabled := True;
        PreviousButton.Visible := True;
      End;
    wbsDisabled:
      Begin
        PreviousButton.Enabled := false;
        PreviousButton.Visible := True;
      End;
    wbsHidden:
      Begin
        PreviousButton.Enabled := false;
        PreviousButton.Visible := false;
      End;
    wbsNoChange:
      ;
  End;

  Case TheNextButton Of
    wbsEnabled:
      Begin
        NextButton.Enabled := True;
        NextButton.Visible := True;
      End;
    wbsDisabled:
      Begin
        NextButton.Enabled := True;
        NextButton.Visible := True;
      End;
    wbsHidden:
      Begin
        NextButton.Enabled := false;
        NextButton.Visible := false;
      End;
    wbsNoChange:
      ;
  End;

  If CrumblineIndex < 0 Then
    Exit;

  For I := 0 To 5 Do
  Begin
    If I = CrumblineIndex Then
      Crumbline.Items[I].ListElement.className := 'page-item active'
    Else
      Crumbline.Items[I].ListElement.className := 'page-item disabled';
  End;

  MyAccountLink.Visible := Not HideMyAccount;
End;

Procedure TMainBookingPage.ChangeThePage(Const ANewPage: TBookingPage; Const ANavDirection: TNavDirection);
Begin
{$IFNDEF RELEASE}
  DebugPanel.Caption := MainData.DebugInfo;
{$ENDIF}
  MyAccountLink.Visible := True;
  UpdateLoggedInSpan;
  Case ANewPage Of
    bpNoChange:
      ;
    bpChooseHoliday:
      ShowForm(THolidayChoiceForm, TBookingPage.bpChooseHoliday, ANavDirection, Nil, True);
    bpLogin:
      ;
    bpRegisterCustomer:
      ;
    bpCustomerDetails:
      ShowForm(TCustomerDetailsForm, TBookingPage.bpCustomerDetails, ANavDirection, Nil, True);
    bpGuestDetails:
      ShowForm(TCustomerDetailsForm, TBookingPage.bpGuestDetails, ANavDirection, Nil, True);
    bpCustomerRequirements:
      ShowForm(TBookingRequirementsForm, TBookingPage.bpCustomerRequirements, ANavDirection, Nil, True);
    bpGuestRequirements:
      ShowForm(TBookingRequirementsForm, TBookingPage.bpGuestRequirements, ANavDirection, Nil, True);
    bpOptionalExtras:
      ShowForm(TOptionalExtrasForm, TBookingPage.bpOptionalExtras, ANavDirection, Nil, True);
    bpReview:
      Begin
        MyAccountLink.Visible := false;
        ShowForm(THolidayPaymentsForm, TBookingPage.bpReview, ANavDirection, Nil, True);
      End;
    bpPay:
      ;
    bpConfirmation:
      ShowForm(TConfirmationForm, TBookingPage.bpConfirmation, ANavDirection, Nil, True);
  End;
End;

Function TMainBookingPage.CloseDisplayedForm(Const SkipValidation: Boolean = True): Boolean;
Begin
  Result := True;
  If Assigned(FDisplayedForm) Then
  Begin
    If FDisplayedForm.ClassType.InheritsFrom(TBaseBookingForm) Then
    Begin
      TBaseBookingForm(FDisplayedForm).TearDown;
      If Not Result Then
        Exit;

      FDisplayedForm.Free;
      FDisplayedForm := Nil;
    End
    Else
      Result := Inherited CloseDisplayedForm(SkipValidation);

  End;
End;

Procedure TMainBookingPage.CloseMainPage;
Begin
  Inherited;

End;

Class Procedure TMainBookingPage.DisplayPage(Const AElementId: String);

  Procedure AfterCreate(AForm: TObject);
  Begin
    // TMainPage(AForm).SetLabels;
  End;

Begin
  If Not Assigned(MainBookingPage) Then
  Begin
    MainBookingPage := TMainBookingPage.CreateNew(AElementId, @AfterCreate);
  End;
End;

Procedure TMainBookingPage.HandleBookingExists;
Var
  lMessage: String;
Begin
  MainData.ProcessingState := TProcessingState.psStandard;
  ShowForm(THolidayChoiceForm, TBookingPage.bpChooseHoliday, TNavDirection.ndNone);
  HideWaitMessage;
  lMessage := 'You can only make one booking for the same holiday. To review your booked holiday, go to "My Account"'
{$IFDEF DEBUG} + ' (' + MainData.LastBookingRefTested + ')'{$ENDIF};
  DisplayMessage(lMessage, TMsgDlgType.mtInformation);
End;

Procedure TMainBookingPage.HandleNextClick;
Var
  lCanProgress: TCanProgress;
  lPreviewOk: Boolean;
Begin
  If FDisplayedForm.ClassType.InheritsFrom(TBaseBookingForm) Then
  Begin

    lPreviewOk := Await(Boolean, TBaseBookingForm(FDisplayedForm).PreviewClose);

    If lPreviewOk Then
      lCanProgress := TBaseBookingForm(FDisplayedForm).CanCloseBookingPage(True)
    Else
      lCanProgress := ccNo;

    ShowWaitMessage;

    Case lCanProgress Of
      ccNo:
        HideWaitMessage;
      ccImmediate:
        Begin
          TBaseBookingForm(FDisplayedForm).SaveTheBooking;
          ChangeThePage(TBaseBookingForm(FDisplayedForm).NextPage, TNavDirection.ndForward);
        End;
      ccWait:
        Begin
          FWaitDirection := TNavDirection.ndForward;
          MainData.OnReadyToProgess := OnReadyToProgress;
          TBaseBookingForm(FDisplayedForm).SaveTheBooking;
        End;
    End;
  End;
End;

Procedure TMainBookingPage.HandlePopState(State: TJSObject; Const AURL, AHash: String);
Var
  ABookingPage: TBookingPage;
  lHash: String;
Begin
  If TBaseBookingForm(FDisplayedForm).PageHash = AHash Then
    Exit;
  If AHash.StartsWith('bp') Then
  Begin
    ABookingPage := TRttiEnumerationType.GetValue<TBookingPage>(AHash);
    If ABookingPage = TBaseBookingForm(FDisplayedForm).PreviousPage Then
      HandlePreviousClick
    Else If ABookingPage = TBaseBookingForm(FDisplayedForm).NextPage Then
      HandleNextClick
    Else
      lHash := AHash;

  End
  Else If AHash.StartsWith('Account') Then
    MainData.SwitchMainPage(TMainAppPage.mpMyAccount)
  Else
  Begin
    lHash := AHash;
  End;
End;

Procedure TMainBookingPage.HandlePreviousClick;
Begin
  If FDisplayedForm.ClassType.InheritsFrom(TBaseBookingForm) Then
  Begin
    If (TBaseBookingForm(FDisplayedForm).CanCloseBookingPage(false) In [ccImmediate, ccWait]) Then
    Begin
      ShowWaitMessage;
      ChangeThePage(TBaseBookingForm(FDisplayedForm).PreviousPage, TNavDirection.ndBackwards);
    End;
  End;
End;

Procedure TMainBookingPage.HandleWaitList;
Begin
  If (MainData.HolidayBooking.WaitList = TWaitListStep.wlEmailSent) Then
  Begin
    MainData.HolidayBooking.WaitList := TWaitListStep.wlNoAdd;
    MainData.ProcessingState := TProcessingState.psStandard;
    MainData.ProcessingState := TProcessingState.psStandard;
    MainData.HolidayBooking.WaitList := TWaitListStep.wlNoAdd;
    ChangeThePage(TBookingPage.bpChooseHoliday, TNavDirection.ndNone);
    DisplayMessage
      ('You have been added to the waiting list. We will be in touch if space becomes available. Thank you');

  End
  Else
    ChangeThePage(TBookingPage.bpCustomerDetails, TNavDirection.ndForward);
End;

Procedure TMainBookingPage.HashChange(Sender: TObject; AHash: String; Var Handled: Boolean);
Var
  ABookingPage: TBookingPage;
  lHash: String;
Begin
  // if AHash.StartsWith('bp') then
  // begin
  // ABookingPage := TRttiEnumerationType.GetValue<TBookingPage>(AHash);
  // if ABookingPage = TBaseBookingForm(FDisplayedForm).PreviousPage then
  // begin
  // HandlePreviousClick;
  // Handled := True;
  // end
  // else if ABookingPage = TBaseBookingForm(FDisplayedForm).NextPage then
  // begin
  // HandleNextClick;
  // Handled := True;
  // end
  // else
  // lHash := AHash;
  //
  // end
  // else if AHash.StartsWith('Account') then
  // begin
  //
  // end
  // else
  // begin
  //
  // end;

End;

Procedure TMainBookingPage.InitForm;
Var
  lName: String;
Begin
  Inherited;
  MainData.AfterLogout := AfterLogout;
  MainData.ErrorMessageProc := ErrorHandler;
  Caption := Application_Title;
  // Application.OnHashChange := HashChange;

{$IFDEF RELEASE}
  DebugPanel.Visible := false;
{$ENDIF}
  MyAccountLink.Visible := True;
  SignOutLink.Visible := false;

  ShowWaitMessage;

  UpdateLoggedInSpan;

  Case MainData.ProcessingState Of
    psLoadingPreviousBooking:
      Begin
        MainData.ProcessingState := TProcessingState.psStandard;
        ChangeThePage(TBookingPage.bpChooseHoliday, TNavDirection.ndNone);
      End;
    psContinuingBooking:
      ChangeThePage(TBookingPage.bpChooseHoliday, TNavDirection.ndNone);
    psBookingExists:
      HandleBookingExists;
    psWaitList:
      HandleWaitList;
  Else
    Begin
      If (Not MainData.ChoiceComplete) Or MainData.PageSwitched Then
        ChangeThePage(TBookingPage.bpChooseHoliday, TNavDirection.ndNone)
      Else
        ChangeThePage(TBookingPage.bpCustomerDetails, TNavDirection.ndForward);
    End;
  End;

End;

Procedure TMainBookingPage.MyAccountLinkClick(Sender: TObject);
Begin
  MainData.SwitchMainPage(TMainAppPage.mpMyAccount);
End;

Procedure TMainBookingPage.NextButtonClick(Sender: TObject);
Begin
  HandleNextClick;
End;

Procedure TMainBookingPage.OnFormSelfClose(Sender: TObject);
Begin

End;

Procedure TMainBookingPage.OnReadyToProgress(Const CanProgress: Boolean);
Begin
  If CanProgress Then
  Begin
    If MainData.ProcessingState = TProcessingState.psWaitList Then
      HandleWaitList
    Else
      ChangeThePage(TBaseBookingForm(FDisplayedForm).NextPage, FWaitDirection);
  End;
  MainData.OnReadyToProgess := Nil;
  FWaitDirection := TNavDirection.ndNone;
End;

Procedure TMainBookingPage.PreviousButtonClick(Sender: TObject);
Begin
  HandlePreviousClick;
End;

Procedure TMainBookingPage.ShowConfirmationPage;
Begin
  ChangeThePage(TBookingPage.bpConfirmation, TNavDirection.ndForward);
End;

Procedure TMainBookingPage.ShowForm(AFormClass: TFormClass; Const ADisplayedPage: TBookingPage;
  Const ANavDirection: TNavDirection; AProc: TFormCreatedProc = Nil; AlwaysClose: Boolean = false);

{ The Process is
  Form Created
  AForm.WebFormCreate;
  AForm.DisplayingAs
  - Set CustomerType (Primary, Secondary)
  AForm.InitForm
  AForm.LoadBooking
}

[async]
  Procedure AfterFormCreated(AForm: TObject); async;
  Begin

    If AFormClass.InheritsFrom(TCoreWebForm) Then
      TCoreWebForm(AForm).OnSelfClose := OnFormSelfClose;

    If AFormClass.InheritsFrom(TBaseBookingForm) Then
    Begin
      TBaseBookingForm(AForm).NavDirection := ANavDirection;
      TBaseBookingForm(AForm).OnBookingPageChange := BookingPageUpdate;
      TBaseBookingForm(AForm).DisplayingAs := ADisplayedPage;
      Await(TBaseBookingForm(AForm).InitForm);
      TBaseBookingForm(AForm).LoadBooking;
      TBaseBookingForm(AForm).AfterLoaded;

      HideWaitMessage;
    End;

    If Assigned(AProc) Then
      AProc(AForm);

  End;

Begin
  // Note that MainPanel is no longer a component. This now just uses a div on the
  // template with that id.
  If Assigned(FDisplayedForm) And (FDisplayedForm.ClassType = AFormClass) And (AlwaysClose = false) Then
    Exit;

  If Not CloseDisplayedForm(True) Then
    Exit;

  FDisplayedPage := ADisplayedPage;

  Application.CreateForm(AFormClass, 'MainPanel', FDisplayedForm, @AfterFormCreated);

End;

Procedure TMainBookingPage.SignOutLinkClick(Sender: TObject);
Begin
  MainData.Logout(TLogoutMethod.lomManual);
  ShowForm(THolidayChoiceForm, TBookingPage.bpChooseHoliday, TNavDirection.ndNone);
  SignOutLink.Visible := false;
End;

Procedure TMainBookingPage.UpdateLoggedInSpan;
Var
  lName: String;
Begin
  If MainData.IsLoggedIn Then
  Begin
    lName := MainData.CustomerName;
    If lName = '' Then
      LoggedInUserSpan.HTML.Text := 'You are signed in'
    Else
      LoggedInUserSpan.HTML.Text := lName + ', you are signed in';
    LoggedInUserSpan.Visible := True;
  End
  Else
    LoggedInUserSpan.Visible := false;

End;

procedure TMainBookingPage.LoadDFMValues;
begin
  inherited LoadDFMValues;

  MyAccountLink := TLabel.Create('MyAccountLink');
  SignOutLink := TLabel.Create('SignOutLink');
  PreviousButton := TButton.Create('PreviousButton');
  NextButton := TButton.Create('NextButton');
  Crumbline := TListControl.Create('crumbline');
  DebugPanel := TPanel.Create('DebugPanel');
  LoggedInUserSpan := THTMLSpan.Create('LoggedInUserSpan');

  MyAccountLink.BeforeLoadDFMValues;
  SignOutLink.BeforeLoadDFMValues;
  PreviousButton.BeforeLoadDFMValues;
  NextButton.BeforeLoadDFMValues;
  Crumbline.BeforeLoadDFMValues;
  DebugPanel.BeforeLoadDFMValues;
  LoggedInUserSpan.BeforeLoadDFMValues;
  try
    Cursor := crHandPoint;
    Caption := 'MyAccountLink';
    MyAccountLink.SetParentComponent(Self);
    MyAccountLink.Name := 'MyAccountLink';
    MyAccountLink.Left := 488;
    MyAccountLink.Top := 64;
    MyAccountLink.Width := 77;
    MyAccountLink.Height := 18;
    MyAccountLink.Cursor := crHandPoint;
    MyAccountLink.Caption := 'My Account';
    MyAccountLink.ElementFont := efCSS;
    MyAccountLink.ElementPosition := epIgnore;
    MyAccountLink.HeightStyle := ssAuto;
    MyAccountLink.HeightPercent := 100.000000000000000000;
    MyAccountLink.WidthStyle := ssAuto;
    MyAccountLink.WidthPercent := 100.000000000000000000;
    SetEvent(MyAccountLink, Self, 'OnClick', 'MyAccountLinkClick');
    SignOutLink.SetParentComponent(Self);
    SignOutLink.Name := 'SignOutLink';
    SignOutLink.Left := 488;
    SignOutLink.Top := 88;
    SignOutLink.Width := 55;
    SignOutLink.Height := 18;
    SignOutLink.Caption := 'Sign Out';
    SignOutLink.ElementFont := efCSS;
    SignOutLink.ElementPosition := epIgnore;
    SignOutLink.HeightStyle := ssAuto;
    SignOutLink.HeightPercent := 100.000000000000000000;
    SignOutLink.WidthPercent := 100.000000000000000000;
    SetEvent(SignOutLink, Self, 'OnClick', 'SignOutLinkClick');
    PreviousButton.SetParentComponent(Self);
    PreviousButton.Name := 'PreviousButton';
    PreviousButton.Left := 104;
    PreviousButton.Top := 352;
    PreviousButton.Width := 96;
    PreviousButton.Height := 25;
    PreviousButton.Caption := 'Previous';
    PreviousButton.ChildOrder := 1;
    PreviousButton.ElementClassName := 'btn btn-primary';
    PreviousButton.ElementFont := efCSS;
    PreviousButton.ElementPosition := epIgnore;
    PreviousButton.HeightStyle := ssAuto;
    PreviousButton.HeightPercent := 100.000000000000000000;
    PreviousButton.WidthStyle := ssAuto;
    PreviousButton.WidthPercent := 100.000000000000000000;
    SetEvent(PreviousButton, Self, 'OnClick', 'PreviousButtonClick');
    NextButton.SetParentComponent(Self);
    NextButton.Name := 'NextButton';
    NextButton.Left := 344;
    NextButton.Top := 352;
    NextButton.Width := 96;
    NextButton.Height := 25;
    NextButton.Caption := 'Next';
    NextButton.ChildOrder := 1;
    NextButton.ElementClassName := 'btn btn-primary';
    NextButton.ElementFont := efCSS;
    NextButton.ElementPosition := epIgnore;
    NextButton.HeightStyle := ssAuto;
    NextButton.HeightPercent := 100.000000000000000000;
    NextButton.WidthStyle := ssAuto;
    NextButton.WidthPercent := 100.000000000000000000;
    SetEvent(NextButton, Self, 'OnClick', 'NextButtonClick');
    Crumbline.SetParentComponent(Self);
    Crumbline.Name := 'Crumbline';
    Crumbline.Left := 112;
    Crumbline.Top := 176;
    Crumbline.Width := 300;
    Crumbline.Height := 129;
    Crumbline.ElementClassName := 'pagination';
    Crumbline.HeightStyle := ssAuto;
    Crumbline.WidthStyle := ssAuto;
    Crumbline.HeightPercent := 100.000000000000000000;
    Crumbline.WidthPercent := 100.000000000000000000;
    Crumbline.ChildOrder := 3;
    Crumbline.DefaultItemClassName := 'page-item';
    Crumbline.DefaultItemLinkClassName := 'page-link';
    Crumbline.ElementFont := efCSS;
    Crumbline.ElementPosition := epIgnore;
    Crumbline.ElementListClassName := 'pagination';
    Crumbline.Items.Clear;
    with Crumbline.Items.Add do
    begin
      ItemClassName := 'page-item disabled';
      LinkClassName := 'page-link';
      Text := 'Select Holiday';
    end;
    with Crumbline.Items.Add do
    begin
      ItemClassName := 'page-item disabled';
      LinkClassName := 'page-link';
      Text := 'Your Details';
    end;
    with Crumbline.Items.Add do
    begin
      ItemClassName := 'page-item disabled';
      LinkClassName := 'page-link';
      Text := 'Guest Details';
    end;
    with Crumbline.Items.Add do
    begin
      ItemClassName := 'page-item disabled';
      LinkClassName := 'page-link';
      Text := 'Optional Extras';
    end;
    with Crumbline.Items.Add do
    begin
      ItemClassName := 'page-item disabled';
      LinkClassName := 'page-link';
      Text := 'Review and Pay';
    end;
    with Crumbline.Items.Add do
    begin
      ItemClassName := 'page-item disabled';
      LinkClassName := 'page-link';
      Text := 'Confirmation';
    end;
    Crumbline.Style := lsListGroup;
    DebugPanel.SetParentComponent(Self);
    DebugPanel.Name := 'DebugPanel';
    DebugPanel.Left := 40;
    DebugPanel.Top := 408;
    DebugPanel.Width := 225;
    DebugPanel.Height := 60;
    DebugPanel.ElementClassName := 'card';
    DebugPanel.HeightStyle := ssAuto;
    DebugPanel.WidthStyle := ssAuto;
    DebugPanel.ChildOrder := 5;
    DebugPanel.ElementBodyClassName := 'card-body';
    DebugPanel.ElementFont := efCSS;
    DebugPanel.ElementPosition := epIgnore;
    DebugPanel.TabOrder := 5;
    LoggedInUserSpan.SetParentComponent(Self);
    LoggedInUserSpan.Name := 'LoggedInUserSpan';
    LoggedInUserSpan.Left := 136;
    LoggedInUserSpan.Top := 32;
    LoggedInUserSpan.Width := 100;
    LoggedInUserSpan.Height := 40;
    LoggedInUserSpan.HeightStyle := ssAuto;
    LoggedInUserSpan.WidthStyle := ssAuto;
    LoggedInUserSpan.ChildOrder := 8;
    LoggedInUserSpan.ElementPosition := epIgnore;
    LoggedInUserSpan.ElementFont := efCSS;
    LoggedInUserSpan.Role := '';
    LoggedInUserSpan.Visible := False;
  finally
    MyAccountLink.AfterLoadDFMValues;
    SignOutLink.AfterLoadDFMValues;
    PreviousButton.AfterLoadDFMValues;
    NextButton.AfterLoadDFMValues;
    Crumbline.AfterLoadDFMValues;
    DebugPanel.AfterLoadDFMValues;
    LoggedInUserSpan.AfterLoadDFMValues;
  end;
end;

End.
