unit AccountBookingDetails;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  AccountBaseForm,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  Data.DB,
  WEBLib.DB,
  WEBLib.WebCtrls,
  Vcl.Controls,
  WEBLib.DBCtrls,
  WEBLib.ExtCtrls,
  Cyrus.Enumerations;

type
  TAccountBookingDetailsPage = class(TAccountBasePage)
    OtherInfo: TPanel;
    ExtraNightsBeforeLabel: TDBLabel;
    ExtraNightsAfterLabel: TDBLabel;
    RoomUpgradeLabel: TDBLabel;
    DogsAllowed: TPanel;
    HasDogLabel: TLabel;
    PainterDetails: TPanel;
    FirstNameLabel: TLabel;
    SurnameLabel: TLabel;
    EmailLabel: TLabel;
    VaccinatedLabel: TLabel;
    EmergencyContactNameLabel: TLabel;
    EmergencyRelationLabel: TLabel;
    EmergencyTelephoneLabel: TLabel;
    DietaryReqsLabel: TLabel;
    MobilityLabel: TLabel;
    OtherNeedsLabel: TLabel;
    VaccinatedDate: TPanel;
    VaccinatedDateLabel: TLabel;
    PainterPanel: TPanel;
    Media1Label: TLabel;
    ChairPanel: TPanel;
    FoldingChairLabel: TLabel;
    UKOnlyPanel: TPanel;
    EaselRequiredLabel: TLabel;
    BoardRequiredLabel: TLabel;
    Media2Panel: TPanel;
    Media2Label: TLabel;
    AddressLabel: THTMLSpan;
    PriceBreakDown: TPanel;
    HolidayBookingCosts: THTMLSpan;
    HolidayDetails: TPanel;
    DestinationLabel: TLabel;
    HotelLabel: TLabel;
    HolidayLabel: TLabel;
    PainterCountLabel: TLabel;
    RoomTypeLabel: TLabel;
    NPPInfo: TPanel;
    NPPCountLabel: TLabel;
    Room2Panel: TPanel;
    RoomType2Label: TLabel;
    BookingSource: TDataSource;
    GuestSource: TDataSource;
    HolidayDetailsButton: TButton;
    PainterDetailsButton: TButton;
    GuestDetailsButton: TButton;
    OtherInfoButton: TButton;
    HearAboutLabel: THTMLDiv;
    HomePhonePanel: TPanel;
    PhoneLabel: TLabel;
    TravelPanel: TPanel;
    TransportLabel: TLabel;
    OtherTravelLabel: TDBLabel;
    MobileLabel: TLabel;
    procedure GuestDetailsButtonClick(Sender: TObject);
    procedure HolidayDetailsButtonClick(Sender: TObject);
    procedure OtherInfoButtonClick(Sender: TObject);
    procedure PainterDetailsButtonClick(Sender: TObject);
  private
    { Private declarations }
    FLoadedCustomer: TCustomerType;
    procedure SetPainterSummary(const CustomerType: TCustomerType);
  protected
    procedure InitForm; override;
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  AccountBookingDetailsPage: TAccountBookingDetailsPage;

implementation

{$R *.dfm}

uses
  MainDataModule,
  SMX.Web.Utils,
  Holiday.Utils;

procedure TAccountBookingDetailsPage.GuestDetailsButtonClick(Sender: TObject);
begin
  OtherInfo.Visible := False;
  HolidayDetails.Visible := False;
  HolidayDetailsButton.ElementClassName := 'btn btn-secondary';
  PainterDetailsButton.ElementClassName := 'btn btn-secondary';
  GuestDetailsButton.ElementClassName := 'btn btn-primary';
  OtherInfoButton.ElementClassName := 'btn btn-secondary';

  SetPainterSummary(TCustomerType.Secondary);
end;

procedure TAccountBookingDetailsPage.HolidayDetailsButtonClick(Sender: TObject);
begin
  PainterDetails.Visible := False;
  OtherInfo.Visible := False;
  HolidayDetails.Visible := True;
  HolidayDetailsButton.ElementClassName := 'btn btn-primary';
  PainterDetailsButton.ElementClassName := 'btn btn-secondary';
  GuestDetailsButton.ElementClassName := 'btn btn-secondary';
  OtherInfoButton.ElementClassName := 'btn btn-secondary';
end;

{ TBookingDetailsPage }

procedure TAccountBookingDetailsPage.InitForm;
var
  lValue: Boolean;
begin
  inherited;

  FLoadedCustomer := TCustomerType.None;

  if MainData.HolidayBooking.GuestCount = 1 then
  begin
    GuestDetailsButton.Visible := False;
  end
  else
  begin
    if MainData.HolidayBooking.Painters = 2 then
    begin
      GuestDetailsButton.Caption := 'Painter 2 Details';
      PainterDetailsButton.Caption := 'Painter 1 Details';
    end
    else
      GuestDetailsButton.Caption := 'Non-Painter Details';
  end;

  DestinationLabel.Caption := MainData.HolidayBooking.VenueName;
  HotelLabel.Caption := MainData.HotelDatasetName.Value;
  HolidayLabel.Caption := MainData.HolidayDataTitle.Value;
  PainterCountLabel.Caption := MainData.BookingDatasetNumberOfPainters.AsString;
  RoomTypeLabel.Caption := Room_Type_Description[MainData.HolidayBooking.RoomType];

  if MainData.HolidayBooking.RoomType2 <> TRoomType.None then
  begin
    RoomType2Label.Caption := Room_Type_Description[MainData.HolidayBooking.RoomType2];
    Room2Panel.Visible := True;
  end;

  NPPInfo.Visible := MainData.HolidayBooking.NonPainters > 0;
  if NPPInfo.Visible then
     NPPCountLabel.Caption := '1'; //MainData.HolidayBooking.NonPainters.ToString;

  if MainData.BookingDatasetWheredidyouhearaboutus.Value > 0 then
  begin
    if MainData.HearAboutOptions.Locate('HearAboutId', MainData.BookingDatasetWheredidyouhearaboutus.Value, []) then
      HearAboutLabel.HTML.Text := MainData.HearAboutOptionsDescription.Value
    else
      HearAboutLabel.HTML.Text := 'not specified';
  end
  else
    HearAboutLabel.HTML.Text := 'not specified';

  DogsAllowed.Visible := MainData.HotelDatasetDogsallowed.Value;
  if DogsAllowed.Visible then
  begin
    lValue := MainData.BookingDatasetHasDog.Value;
    HasDogLabel.Caption := YesNo[MainData.BookingDatasetHasDog.Value];
  end;

  PriceBreakDown.Visible := MainData.BookingCosts.Active and (not MainData.BookingCosts.IsEmpty);

  if PriceBreakDown.Visible then
    HolidayBookingCosts.HTML.Text := THolidayUtils.BookingCostsTable;

end;

procedure TAccountBookingDetailsPage.OtherInfoButtonClick(Sender: TObject);
begin
  HolidayDetails.Visible := False;
  PainterDetails.Visible := False;
  OtherInfo.Visible := True;
  HolidayDetailsButton.ElementClassName := 'btn btn-secondary';
  PainterDetailsButton.ElementClassName := 'btn btn-secondary';
  GuestDetailsButton.ElementClassName := 'btn btn-secondary';
  OtherInfoButton.ElementClassName := 'btn btn-primary';
end;

procedure TAccountBookingDetailsPage.PainterDetailsButtonClick(Sender: TObject);
begin
  OtherInfo.Visible := False;
  HolidayDetails.Visible := False;
  HolidayDetailsButton.ElementClassName := 'btn btn-secondary';
  PainterDetailsButton.ElementClassName := 'btn btn-primary';
  GuestDetailsButton.ElementClassName := 'btn btn-secondary';
  OtherInfoButton.ElementClassName := 'btn btn-secondary';

  SetPainterSummary(TCustomerType.Primary);
end;

procedure TAccountBookingDetailsPage.SetPainterSummary(const CustomerType: TCustomerType);
var
  lDataset: TDataset;
  lIsPainter: Boolean;
begin
  if not MainData.LocateGuestRecord(CustomerType) then
    { TODO : Needs to be a bit more than an exit }
    Exit;

  if FLoadedCustomer = CustomerType then
    Exit;

  case CustomerType of
    TCustomerType.Primary:
      lDataset := MainData.CustomerDataset;
    TCustomerType.Secondary:
      lDataset := MainData.Guest2;
  end;

  lIsPainter := MainData.GuestDatasetGuestType.Value = guest_type_bool[False];

  FirstNameLabel.Caption := lDataset.FieldByName('FirstName').AsString;
  SurnameLabel.Caption := lDataset.FieldByName('LastName').AsString;

  if (CustomerType = TCustomerType.Secondary) and MainData.Guest2AddressSameAsParent.Value then
  begin
     AddressLabel.HTML.Text := 'Same';
     HomePhonePanel.Visible := False;
     TravelPanel.Visible := False;
  end
  else
  begin
     AddressLabel.HTML.Text := THolidayUtils.FormatAddress(lDataset);
     PhoneLabel.Caption := lDataset.FieldByName('HomeTelephoneNumber').AsString;
     TransportLabel.Caption := Transport_Type_Idx[MainData.GuestDatasetTransportId.Value];
     HomePhonePanel.Visible := True;
     TravelPanel.Visible := True;
  end;

    if lDataset.FieldByName('Email').AsString = '' then
     EmailLabel.Caption := 'Not specified'
  else
     EmailLabel.Caption := lDataset.FieldByName('Email').AsString;

  if lDataset.FieldByName('MobileNumber').AsString = '' then
     MobileLabel.Caption := 'Not specified'
  else
     MobileLabel.Caption := lDataset.FieldByName('MobileNumber').AsString;

  if MainData.GuestDatasetEmergencycontactname.Value <> '' then
    EmergencyContactNameLabel.Caption := MainData.GuestDatasetEmergencycontactname.Value
  else
    EmergencyContactNameLabel.Caption := 'none specified';

  if MainData.GuestDatasetEmergencycontactrelation.Value <> '' then
    EmergencyRelationLabel.Caption := MainData.GuestDatasetEmergencycontactrelation.Value
  else
    EmergencyRelationLabel.Caption := 'not specified';

  if MainData.GuestDatasetEmergencycontacttelephone.Value <> '' then
    EmergencyTelephoneLabel.Caption := MainData.GuestDatasetEmergencycontacttelephone.Value
  else
    EmergencyTelephoneLabel.Caption := 'not specified';

  if MainData.GuestDatasetDietaryrequirements.Value <> '' then
    DietaryReqsLabel.Caption := MainData.GuestDatasetDietaryrequirements.Value
  else
    DietaryReqsLabel.Caption := 'none specified';

  if MainData.GuestDatasetMobility.Value <> '' then
    MobilityLabel.Caption := MainData.GuestDatasetMobility.Value
  else
    MobilityLabel.Caption := 'none specified';

  if MainData.GuestDatasetOtherneeds.Value <> '' then
    OtherNeedsLabel.Caption := MainData.GuestDatasetOtherneeds.Value
  else
    OtherNeedsLabel.Caption := 'none specified';

  if lDataset.FieldByName('FullyVaccinated').AsInteger = 1 then
  begin
    VaccinatedLabel.Caption := 'Yes';
    VaccinatedDate.Visible := True;
    VaccinatedDateLabel.Caption := FormatDateTime('dd, mmm yyyy', lDataset.FieldByName('DateOfLastVaccination')
      .AsDateTime);
  end
  else
  begin
    VaccinatedLabel.Caption := 'No';
    VaccinatedDate.Visible := False;
  end;

  PainterPanel.Visible := lIsPainter;

  if PainterPanel.Visible then
  begin
    ChairPanel.Visible := (MainData.HolidaysDatasetHolidayType.Value = 'Location') and
      (not MainData.SelectedVenue.IsAbroad);
    if ChairPanel.Visible then
      FoldingChairLabel.Caption := Yes_No[MainData.GuestDatasetFoldingchairrequired.Value];

    UKOnlyPanel.Visible := not MainData.SelectedVenue.IsAbroad;
    if UKOnlyPanel.Visible then
    begin
      EaselRequiredLabel.Caption := Yes_No[MainData.GuestDatasetEaselrequired.Value];
      BoardRequiredLabel.Caption := Yes_No[MainData.GuestDatasetBoardrequired.Value];
    end;

    Media1Label.Caption := MainData.GuestDatasetMedia1.Value;
    if MainData.GuestDatasetMedia2.Value <> '' then
    begin
      Media2Label.Caption := MainData.GuestDatasetMedia2.Value;
      Media2Panel.Visible := True;
    end
    else
      Media2Panel.Visible := False;

  end;

  PainterDetails.Visible := True;

end;

procedure TAccountBookingDetailsPage.LoadDFMValues;
begin
  inherited LoadDFMValues;

  OtherInfo := TPanel.Create('OtherInfo');
  ExtraNightsBeforeLabel := TDBLabel.Create('ExtraNightsBeforeLabel');
  ExtraNightsAfterLabel := TDBLabel.Create('ExtraNightsAfterLabel');
  RoomUpgradeLabel := TDBLabel.Create('RoomUpgradeLabel');
  DogsAllowed := TPanel.Create('DogsAllowed');
  HasDogLabel := TLabel.Create('HasDogLabel');
  HearAboutLabel := THTMLDiv.Create('HearAboutLabel');
  PainterDetails := TPanel.Create('PainterDetails');
  FirstNameLabel := TLabel.Create('FirstNameLabel');
  SurnameLabel := TLabel.Create('SurnameLabel');
  EmailLabel := TLabel.Create('EmailLabel');
  VaccinatedLabel := TLabel.Create('VaccinatedLabel');
  EmergencyContactNameLabel := TLabel.Create('EmergencyContactNameLabel');
  EmergencyRelationLabel := TLabel.Create('EmergencyRelationLabel');
  EmergencyTelephoneLabel := TLabel.Create('EmergencyTelephoneLabel');
  DietaryReqsLabel := TLabel.Create('DietaryReqsLabel');
  MobilityLabel := TLabel.Create('MobilityLabel');
  OtherNeedsLabel := TLabel.Create('OtherNeedsLabel');
  MobileLabel := TLabel.Create('MobileLabel');
  VaccinatedDate := TPanel.Create('VaccinatedDate');
  VaccinatedDateLabel := TLabel.Create('VaccinatedDateLabel');
  PainterPanel := TPanel.Create('PainterPanel');
  Media1Label := TLabel.Create('Media1Label');
  ChairPanel := TPanel.Create('ChairPanel');
  FoldingChairLabel := TLabel.Create('FoldingChairLabel');
  UKOnlyPanel := TPanel.Create('UKOnlyPanel');
  EaselRequiredLabel := TLabel.Create('EaselRequiredLabel');
  BoardRequiredLabel := TLabel.Create('BoardRequiredLabel');
  Media2Panel := TPanel.Create('Media2Panel');
  Media2Label := TLabel.Create('Media2Label');
  AddressLabel := THTMLSpan.Create('AddressLabel');
  HomePhonePanel := TPanel.Create('HomePhonePanel');
  PhoneLabel := TLabel.Create('PhoneLabel');
  TravelPanel := TPanel.Create('TravelPanel');
  TransportLabel := TLabel.Create('TransportLabel');
  OtherTravelLabel := TDBLabel.Create('OtherTravelLabel');
  PriceBreakDown := TPanel.Create('PriceBreakDown');
  HolidayBookingCosts := THTMLSpan.Create('HolidayBookingCosts');
  HolidayDetails := TPanel.Create('HolidayDetails');
  DestinationLabel := TLabel.Create('DestinationLabel');
  HotelLabel := TLabel.Create('HotelLabel');
  HolidayLabel := TLabel.Create('HolidayLabel');
  PainterCountLabel := TLabel.Create('PainterCountLabel');
  RoomTypeLabel := TLabel.Create('RoomTypeLabel');
  NPPInfo := TPanel.Create('NPPInfo');
  NPPCountLabel := TLabel.Create('NPPCountLabel');
  Room2Panel := TPanel.Create('Room2Panel');
  RoomType2Label := TLabel.Create('RoomType2Label');
  HolidayDetailsButton := TButton.Create('HolidayDetailsButton');
  PainterDetailsButton := TButton.Create('PainterDetailsButton');
  GuestDetailsButton := TButton.Create('GuestDetailsButton');
  OtherInfoButton := TButton.Create('Other Information');
  BookingSource := TDataSource.Create(Self);
  GuestSource := TDataSource.Create(Self);

  OtherInfo.BeforeLoadDFMValues;
  ExtraNightsBeforeLabel.BeforeLoadDFMValues;
  ExtraNightsAfterLabel.BeforeLoadDFMValues;
  RoomUpgradeLabel.BeforeLoadDFMValues;
  DogsAllowed.BeforeLoadDFMValues;
  HasDogLabel.BeforeLoadDFMValues;
  HearAboutLabel.BeforeLoadDFMValues;
  PainterDetails.BeforeLoadDFMValues;
  FirstNameLabel.BeforeLoadDFMValues;
  SurnameLabel.BeforeLoadDFMValues;
  EmailLabel.BeforeLoadDFMValues;
  VaccinatedLabel.BeforeLoadDFMValues;
  EmergencyContactNameLabel.BeforeLoadDFMValues;
  EmergencyRelationLabel.BeforeLoadDFMValues;
  EmergencyTelephoneLabel.BeforeLoadDFMValues;
  DietaryReqsLabel.BeforeLoadDFMValues;
  MobilityLabel.BeforeLoadDFMValues;
  OtherNeedsLabel.BeforeLoadDFMValues;
  MobileLabel.BeforeLoadDFMValues;
  VaccinatedDate.BeforeLoadDFMValues;
  VaccinatedDateLabel.BeforeLoadDFMValues;
  PainterPanel.BeforeLoadDFMValues;
  Media1Label.BeforeLoadDFMValues;
  ChairPanel.BeforeLoadDFMValues;
  FoldingChairLabel.BeforeLoadDFMValues;
  UKOnlyPanel.BeforeLoadDFMValues;
  EaselRequiredLabel.BeforeLoadDFMValues;
  BoardRequiredLabel.BeforeLoadDFMValues;
  Media2Panel.BeforeLoadDFMValues;
  Media2Label.BeforeLoadDFMValues;
  AddressLabel.BeforeLoadDFMValues;
  HomePhonePanel.BeforeLoadDFMValues;
  PhoneLabel.BeforeLoadDFMValues;
  TravelPanel.BeforeLoadDFMValues;
  TransportLabel.BeforeLoadDFMValues;
  OtherTravelLabel.BeforeLoadDFMValues;
  PriceBreakDown.BeforeLoadDFMValues;
  HolidayBookingCosts.BeforeLoadDFMValues;
  HolidayDetails.BeforeLoadDFMValues;
  DestinationLabel.BeforeLoadDFMValues;
  HotelLabel.BeforeLoadDFMValues;
  HolidayLabel.BeforeLoadDFMValues;
  PainterCountLabel.BeforeLoadDFMValues;
  RoomTypeLabel.BeforeLoadDFMValues;
  NPPInfo.BeforeLoadDFMValues;
  NPPCountLabel.BeforeLoadDFMValues;
  Room2Panel.BeforeLoadDFMValues;
  RoomType2Label.BeforeLoadDFMValues;
  HolidayDetailsButton.BeforeLoadDFMValues;
  PainterDetailsButton.BeforeLoadDFMValues;
  GuestDetailsButton.BeforeLoadDFMValues;
  OtherInfoButton.BeforeLoadDFMValues;
  CloseButton.BeforeLoadDFMValues;
  BookingSource.BeforeLoadDFMValues;
  GuestSource.BeforeLoadDFMValues;
  try
    Width := 968;
    Height := 794;
    OtherInfo.SetParentComponent(Self);
    OtherInfo.Name := 'OtherInfo';
    OtherInfo.Left := 450;
    OtherInfo.Top := 536;
    OtherInfo.Width := 321;
    OtherInfo.Height := 232;
    OtherInfo.ElementClassName := 'card';
    OtherInfo.HeightStyle := ssAuto;
    OtherInfo.WidthStyle := ssAuto;
    OtherInfo.BorderColor := clNone;
    OtherInfo.BorderStyle := bsNone;
    OtherInfo.ChildOrder := 10;
    OtherInfo.Color := clWindow;
    OtherInfo.ElementBodyClassName := 'card-body';
    OtherInfo.ElementFont := efCSS;
    OtherInfo.ElementPosition := epIgnore;
    OtherInfo.TabOrder := 0;
    OtherInfo.Visible := False;
    ExtraNightsBeforeLabel.SetParentComponent(OtherInfo);
    ExtraNightsBeforeLabel.Name := 'ExtraNightsBeforeLabel';
    ExtraNightsBeforeLabel.Left := 16;
    ExtraNightsBeforeLabel.Top := 23;
    ExtraNightsBeforeLabel.Width := 123;
    ExtraNightsBeforeLabel.Height := 15;
    ExtraNightsBeforeLabel.Caption := 'ExtraNightsBeforeLabel';
    ExtraNightsBeforeLabel.ElementFont := efCSS;
    ExtraNightsBeforeLabel.ElementPosition := epIgnore;
    ExtraNightsBeforeLabel.HeightStyle := ssAuto;
    ExtraNightsBeforeLabel.HeightPercent := 100.000000000000000000;
    ExtraNightsBeforeLabel.WidthStyle := ssAuto;
    ExtraNightsBeforeLabel.WidthPercent := 100.000000000000000000;
    ExtraNightsBeforeLabel.DataField := 'FirstRoomExtraNightsBefore';
    ExtraNightsBeforeLabel.DataSource := BookingSource;
    ExtraNightsAfterLabel.SetParentComponent(OtherInfo);
    ExtraNightsAfterLabel.Name := 'ExtraNightsAfterLabel';
    ExtraNightsAfterLabel.Left := 16;
    ExtraNightsAfterLabel.Top := 47;
    ExtraNightsAfterLabel.Width := 123;
    ExtraNightsAfterLabel.Height := 15;
    ExtraNightsAfterLabel.Caption := 'ExtraNightsBeforeLabel';
    ExtraNightsAfterLabel.ElementFont := efCSS;
    ExtraNightsAfterLabel.ElementPosition := epIgnore;
    ExtraNightsAfterLabel.HeightStyle := ssAuto;
    ExtraNightsAfterLabel.HeightPercent := 100.000000000000000000;
    ExtraNightsAfterLabel.WidthStyle := ssAuto;
    ExtraNightsAfterLabel.WidthPercent := 100.000000000000000000;
    ExtraNightsAfterLabel.DataField := 'FirstRoomExtraNightsAfter';
    ExtraNightsAfterLabel.DataSource := BookingSource;
    RoomUpgradeLabel.SetParentComponent(OtherInfo);
    RoomUpgradeLabel.Name := 'RoomUpgradeLabel';
    RoomUpgradeLabel.Left := 16;
    RoomUpgradeLabel.Top := 95;
    RoomUpgradeLabel.Width := 105;
    RoomUpgradeLabel.Height := 15;
    RoomUpgradeLabel.Caption := 'RoomUpgradeLabel';
    RoomUpgradeLabel.ElementFont := efCSS;
    RoomUpgradeLabel.ElementPosition := epIgnore;
    RoomUpgradeLabel.HeightStyle := ssAuto;
    RoomUpgradeLabel.HeightPercent := 100.000000000000000000;
    RoomUpgradeLabel.WidthStyle := ssAuto;
    RoomUpgradeLabel.WidthPercent := 100.000000000000000000;
    RoomUpgradeLabel.DataField := 'FirstRoomUpgrade';
    RoomUpgradeLabel.DataSource := BookingSource;
    DogsAllowed.SetParentComponent(OtherInfo);
    DogsAllowed.Name := 'DogsAllowed';
    DogsAllowed.Left := 12;
    DogsAllowed.Top := 135;
    DogsAllowed.Width := 129;
    DogsAllowed.Height := 35;
    DogsAllowed.ElementClassName := 'card';
    DogsAllowed.HeightStyle := ssAuto;
    DogsAllowed.WidthStyle := ssAuto;
    DogsAllowed.BorderColor := clNone;
    DogsAllowed.BorderStyle := bsNone;
    DogsAllowed.ChildOrder := 3;
    DogsAllowed.Color := clWindow;
    DogsAllowed.ElementBodyClassName := 'card-body';
    DogsAllowed.ElementFont := efCSS;
    DogsAllowed.ElementPosition := epIgnore;
    DogsAllowed.TabOrder := 0;
    DogsAllowed.Visible := False;
    HasDogLabel.SetParentComponent(DogsAllowed);
    HasDogLabel.Name := 'HasDogLabel';
    HasDogLabel.Left := 11;
    HasDogLabel.Top := 3;
    HasDogLabel.Width := 70;
    HasDogLabel.Height := 15;
    HasDogLabel.Caption := 'HasDogLabel';
    HasDogLabel.ElementFont := efCSS;
    HasDogLabel.ElementPosition := epIgnore;
    HasDogLabel.HeightStyle := ssAuto;
    HasDogLabel.HeightPercent := 100.000000000000000000;
    HasDogLabel.WidthStyle := ssAuto;
    HasDogLabel.WidthPercent := 100.000000000000000000;
    HearAboutLabel.SetParentComponent(OtherInfo);
    HearAboutLabel.Name := 'HearAboutLabel';
    HearAboutLabel.Left := 16;
    HearAboutLabel.Top := 176;
    HearAboutLabel.Width := 100;
    HearAboutLabel.Height := 41;
    HearAboutLabel.HeightStyle := ssAuto;
    HearAboutLabel.WidthStyle := ssAuto;
    HearAboutLabel.ChildOrder := 5;
    HearAboutLabel.ElementPosition := epIgnore;
    HearAboutLabel.ElementFont := efCSS;
    HearAboutLabel.Role := '';
    PainterDetails.SetParentComponent(Self);
    PainterDetails.Name := 'PainterDetails';
    PainterDetails.Left := 462;
    PainterDetails.Top := 25;
    PainterDetails.Width := 385;
    PainterDetails.Height := 505;
    PainterDetails.ElementClassName := 'card';
    PainterDetails.HeightStyle := ssAuto;
    PainterDetails.WidthStyle := ssAuto;
    PainterDetails.BorderColor := clNone;
    PainterDetails.BorderStyle := bsNone;
    PainterDetails.ChildOrder := 10;
    PainterDetails.Color := clWindow;
    PainterDetails.ElementBodyClassName := 'card-body';
    PainterDetails.ElementFont := efCSS;
    PainterDetails.ElementPosition := epIgnore;
    PainterDetails.TabOrder := 1;
    PainterDetails.Visible := False;
    FirstNameLabel.SetParentComponent(PainterDetails);
    FirstNameLabel.Name := 'FirstNameLabel';
    FirstNameLabel.Left := 64;
    FirstNameLabel.Top := 40;
    FirstNameLabel.Width := 82;
    FirstNameLabel.Height := 15;
    FirstNameLabel.Caption := 'FirstNameLabel';
    FirstNameLabel.ElementFont := efCSS;
    FirstNameLabel.ElementPosition := epIgnore;
    FirstNameLabel.HeightStyle := ssAuto;
    FirstNameLabel.HeightPercent := 100.000000000000000000;
    FirstNameLabel.WidthStyle := ssAuto;
    FirstNameLabel.WidthPercent := 100.000000000000000000;
    SurnameLabel.SetParentComponent(PainterDetails);
    SurnameLabel.Name := 'SurnameLabel';
    SurnameLabel.Left := 65;
    SurnameLabel.Top := 64;
    SurnameLabel.Width := 75;
    SurnameLabel.Height := 15;
    SurnameLabel.Caption := 'SurnameLabel';
    SurnameLabel.ElementFont := efCSS;
    SurnameLabel.ElementPosition := epIgnore;
    SurnameLabel.HeightStyle := ssAuto;
    SurnameLabel.HeightPercent := 100.000000000000000000;
    SurnameLabel.WidthStyle := ssAuto;
    SurnameLabel.WidthPercent := 100.000000000000000000;
    EmailLabel.SetParentComponent(PainterDetails);
    EmailLabel.Name := 'EmailLabel';
    EmailLabel.Left := 65;
    EmailLabel.Top := 136;
    EmailLabel.Width := 57;
    EmailLabel.Height := 15;
    EmailLabel.Caption := 'EmailLabel';
    EmailLabel.ElementFont := efCSS;
    EmailLabel.ElementPosition := epIgnore;
    EmailLabel.HeightStyle := ssAuto;
    EmailLabel.HeightPercent := 100.000000000000000000;
    EmailLabel.WidthStyle := ssAuto;
    EmailLabel.WidthPercent := 100.000000000000000000;
    VaccinatedLabel.SetParentComponent(PainterDetails);
    VaccinatedLabel.Name := 'VaccinatedLabel';
    VaccinatedLabel.Left := 65;
    VaccinatedLabel.Top := 160;
    VaccinatedLabel.Width := 85;
    VaccinatedLabel.Height := 15;
    VaccinatedLabel.Caption := 'VaccinatedLabel';
    VaccinatedLabel.ElementFont := efCSS;
    VaccinatedLabel.ElementPosition := epIgnore;
    VaccinatedLabel.HeightStyle := ssAuto;
    VaccinatedLabel.HeightPercent := 100.000000000000000000;
    VaccinatedLabel.WidthStyle := ssAuto;
    VaccinatedLabel.WidthPercent := 100.000000000000000000;
    EmergencyContactNameLabel.SetParentComponent(PainterDetails);
    EmergencyContactNameLabel.Name := 'EmergencyContactNameLabel';
    EmergencyContactNameLabel.Left := 48;
    EmergencyContactNameLabel.Top := 271;
    EmergencyContactNameLabel.Width := 161;
    EmergencyContactNameLabel.Height := 15;
    EmergencyContactNameLabel.Caption := 'EmergencyContactNameLabel';
    EmergencyContactNameLabel.ElementFont := efCSS;
    EmergencyContactNameLabel.ElementPosition := epIgnore;
    EmergencyContactNameLabel.HeightStyle := ssAuto;
    EmergencyContactNameLabel.HeightPercent := 100.000000000000000000;
    EmergencyContactNameLabel.WidthStyle := ssAuto;
    EmergencyContactNameLabel.WidthPercent := 100.000000000000000000;
    EmergencyRelationLabel.SetParentComponent(PainterDetails);
    EmergencyRelationLabel.Name := 'EmergencyRelationLabel';
    EmergencyRelationLabel.Left := 48;
    EmergencyRelationLabel.Top := 303;
    EmergencyRelationLabel.Width := 130;
    EmergencyRelationLabel.Height := 15;
    EmergencyRelationLabel.Caption := 'EmergencyRelationLabel';
    EmergencyRelationLabel.ElementFont := efCSS;
    EmergencyRelationLabel.ElementPosition := epIgnore;
    EmergencyRelationLabel.HeightStyle := ssAuto;
    EmergencyRelationLabel.HeightPercent := 100.000000000000000000;
    EmergencyRelationLabel.WidthStyle := ssAuto;
    EmergencyRelationLabel.WidthPercent := 100.000000000000000000;
    EmergencyTelephoneLabel.SetParentComponent(PainterDetails);
    EmergencyTelephoneLabel.Name := 'EmergencyTelephoneLabel';
    EmergencyTelephoneLabel.Left := 48;
    EmergencyTelephoneLabel.Top := 335;
    EmergencyTelephoneLabel.Width := 141;
    EmergencyTelephoneLabel.Height := 15;
    EmergencyTelephoneLabel.Caption := 'EmergencyTelephoneLabel';
    EmergencyTelephoneLabel.ElementFont := efCSS;
    EmergencyTelephoneLabel.ElementPosition := epIgnore;
    EmergencyTelephoneLabel.HeightStyle := ssAuto;
    EmergencyTelephoneLabel.HeightPercent := 100.000000000000000000;
    EmergencyTelephoneLabel.WidthStyle := ssAuto;
    EmergencyTelephoneLabel.WidthPercent := 100.000000000000000000;
    DietaryReqsLabel.SetParentComponent(PainterDetails);
    DietaryReqsLabel.Name := 'DietaryReqsLabel';
    DietaryReqsLabel.Left := 48;
    DietaryReqsLabel.Top := 359;
    DietaryReqsLabel.Width := 90;
    DietaryReqsLabel.Height := 15;
    DietaryReqsLabel.Caption := 'DietaryReqsLabel';
    DietaryReqsLabel.ElementFont := efCSS;
    DietaryReqsLabel.ElementPosition := epIgnore;
    DietaryReqsLabel.HeightStyle := ssAuto;
    DietaryReqsLabel.HeightPercent := 100.000000000000000000;
    DietaryReqsLabel.WidthStyle := ssAuto;
    DietaryReqsLabel.WidthPercent := 100.000000000000000000;
    MobilityLabel.SetParentComponent(PainterDetails);
    MobilityLabel.Name := 'MobilityLabel';
    MobilityLabel.Left := 48;
    MobilityLabel.Top := 383;
    MobilityLabel.Width := 72;
    MobilityLabel.Height := 15;
    MobilityLabel.Caption := 'MobilityLabel';
    MobilityLabel.ElementFont := efCSS;
    MobilityLabel.ElementPosition := epIgnore;
    MobilityLabel.HeightStyle := ssAuto;
    MobilityLabel.HeightPercent := 100.000000000000000000;
    MobilityLabel.WidthStyle := ssAuto;
    MobilityLabel.WidthPercent := 100.000000000000000000;
    OtherNeedsLabel.SetParentComponent(PainterDetails);
    OtherNeedsLabel.Name := 'OtherNeedsLabel';
    OtherNeedsLabel.Left := 48;
    OtherNeedsLabel.Top := 415;
    OtherNeedsLabel.Width := 91;
    OtherNeedsLabel.Height := 15;
    OtherNeedsLabel.Caption := 'OtherNeedsLabel';
    OtherNeedsLabel.ElementFont := efCSS;
    OtherNeedsLabel.ElementPosition := epIgnore;
    OtherNeedsLabel.HeightStyle := ssAuto;
    OtherNeedsLabel.HeightPercent := 100.000000000000000000;
    OtherNeedsLabel.WidthStyle := ssAuto;
    OtherNeedsLabel.WidthPercent := 100.000000000000000000;
    MobileLabel.SetParentComponent(PainterDetails);
    MobileLabel.Name := 'MobileLabel';
    MobileLabel.Left := 64;
    MobileLabel.Top := 192;
    MobileLabel.Width := 65;
    MobileLabel.Height := 15;
    MobileLabel.Caption := 'MobileLabel';
    MobileLabel.ElementFont := efCSS;
    MobileLabel.HeightStyle := ssAuto;
    MobileLabel.HeightPercent := 100.000000000000000000;
    MobileLabel.WidthPercent := 100.000000000000000000;
    VaccinatedDate.SetParentComponent(PainterDetails);
    VaccinatedDate.Name := 'VaccinatedDate';
    VaccinatedDate.Left := 57;
    VaccinatedDate.Top := 209;
    VaccinatedDate.Width := 129;
    VaccinatedDate.Height := 56;
    VaccinatedDate.ElementClassName := 'card';
    VaccinatedDate.HeightStyle := ssAuto;
    VaccinatedDate.WidthStyle := ssAuto;
    VaccinatedDate.BorderColor := clNone;
    VaccinatedDate.BorderStyle := bsNone;
    VaccinatedDate.ChildOrder := 10;
    VaccinatedDate.Color := clWindow;
    VaccinatedDate.ElementBodyClassName := 'card-body';
    VaccinatedDate.ElementFont := efCSS;
    VaccinatedDate.ElementPosition := epIgnore;
    VaccinatedDate.TabOrder := 0;
    VaccinatedDate.Visible := False;
    VaccinatedDateLabel.SetParentComponent(VaccinatedDate);
    VaccinatedDateLabel.Name := 'VaccinatedDateLabel';
    VaccinatedDateLabel.Left := 3;
    VaccinatedDateLabel.Top := 12;
    VaccinatedDateLabel.Width := 109;
    VaccinatedDateLabel.Height := 15;
    VaccinatedDateLabel.Caption := 'VaccinatedDateLabel';
    VaccinatedDateLabel.ElementFont := efCSS;
    VaccinatedDateLabel.ElementPosition := epIgnore;
    VaccinatedDateLabel.HeightStyle := ssAuto;
    VaccinatedDateLabel.HeightPercent := 100.000000000000000000;
    VaccinatedDateLabel.WidthStyle := ssAuto;
    VaccinatedDateLabel.WidthPercent := 100.000000000000000000;
    PainterPanel.SetParentComponent(PainterDetails);
    PainterPanel.Name := 'PainterPanel';
    PainterPanel.Left := 208;
    PainterPanel.Top := 11;
    PainterPanel.Width := 161;
    PainterPanel.Height := 237;
    PainterPanel.ElementClassName := 'card';
    PainterPanel.HeightStyle := ssAuto;
    PainterPanel.WidthStyle := ssAuto;
    PainterPanel.BorderColor := clNone;
    PainterPanel.BorderStyle := bsNone;
    PainterPanel.ChildOrder := 10;
    PainterPanel.Color := clWindow;
    PainterPanel.ElementBodyClassName := 'card-body';
    PainterPanel.ElementFont := efCSS;
    PainterPanel.ElementPosition := epIgnore;
    PainterPanel.TabOrder := 1;
    PainterPanel.Visible := False;
    Media1Label.SetParentComponent(PainterPanel);
    Media1Label.Name := 'Media1Label';
    Media1Label.Left := 3;
    Media1Label.Top := 142;
    Media1Label.Width := 67;
    Media1Label.Height := 15;
    Media1Label.Caption := 'Media1Label';
    Media1Label.ElementFont := efCSS;
    Media1Label.ElementPosition := epIgnore;
    Media1Label.HeightStyle := ssAuto;
    Media1Label.HeightPercent := 100.000000000000000000;
    Media1Label.WidthStyle := ssAuto;
    Media1Label.WidthPercent := 100.000000000000000000;
    ChairPanel.SetParentComponent(PainterPanel);
    ChairPanel.Name := 'ChairPanel';
    ChairPanel.Left := 3;
    ChairPanel.Top := 67;
    ChairPanel.Width := 129;
    ChairPanel.Height := 58;
    ChairPanel.ElementClassName := 'card';
    ChairPanel.HeightStyle := ssAuto;
    ChairPanel.WidthStyle := ssAuto;
    ChairPanel.BorderColor := clNone;
    ChairPanel.BorderStyle := bsNone;
    ChairPanel.ChildOrder := 1;
    ChairPanel.Color := clWindow;
    ChairPanel.ElementBodyClassName := 'card-body';
    ChairPanel.ElementFont := efCSS;
    ChairPanel.ElementPosition := epIgnore;
    ChairPanel.TabOrder := 0;
    ChairPanel.Visible := False;
    FoldingChairLabel.SetParentComponent(ChairPanel);
    FoldingChairLabel.Name := 'FoldingChairLabel';
    FoldingChairLabel.Left := 3;
    FoldingChairLabel.Top := 22;
    FoldingChairLabel.Width := 96;
    FoldingChairLabel.Height := 15;
    FoldingChairLabel.Caption := 'FoldingChairLabel';
    FoldingChairLabel.ElementFont := efCSS;
    FoldingChairLabel.ElementPosition := epIgnore;
    FoldingChairLabel.HeightStyle := ssAuto;
    FoldingChairLabel.HeightPercent := 100.000000000000000000;
    FoldingChairLabel.WidthStyle := ssAuto;
    FoldingChairLabel.WidthPercent := 100.000000000000000000;
    UKOnlyPanel.SetParentComponent(PainterPanel);
    UKOnlyPanel.Name := 'UKOnlyPanel';
    UKOnlyPanel.Left := 3;
    UKOnlyPanel.Top := 11;
    UKOnlyPanel.Width := 143;
    UKOnlyPanel.Height := 58;
    UKOnlyPanel.ElementClassName := 'card';
    UKOnlyPanel.HeightStyle := ssAuto;
    UKOnlyPanel.WidthStyle := ssAuto;
    UKOnlyPanel.BorderColor := clNone;
    UKOnlyPanel.BorderStyle := bsNone;
    UKOnlyPanel.ChildOrder := 1;
    UKOnlyPanel.Color := clWindow;
    UKOnlyPanel.ElementBodyClassName := 'card-body';
    UKOnlyPanel.ElementFont := efCSS;
    UKOnlyPanel.ElementPosition := epIgnore;
    UKOnlyPanel.TabOrder := 1;
    UKOnlyPanel.Visible := False;
    EaselRequiredLabel.SetParentComponent(UKOnlyPanel);
    EaselRequiredLabel.Name := 'EaselRequiredLabel';
    EaselRequiredLabel.Left := 3;
    EaselRequiredLabel.Top := 14;
    EaselRequiredLabel.Width := 101;
    EaselRequiredLabel.Height := 15;
    EaselRequiredLabel.Caption := 'EaselRequiredLabel';
    EaselRequiredLabel.ElementFont := efCSS;
    EaselRequiredLabel.ElementPosition := epIgnore;
    EaselRequiredLabel.HeightStyle := ssAuto;
    EaselRequiredLabel.HeightPercent := 100.000000000000000000;
    EaselRequiredLabel.WidthStyle := ssAuto;
    EaselRequiredLabel.WidthPercent := 100.000000000000000000;
    BoardRequiredLabel.SetParentComponent(UKOnlyPanel);
    BoardRequiredLabel.Name := 'BoardRequiredLabel';
    BoardRequiredLabel.Left := 3;
    BoardRequiredLabel.Top := 38;
    BoardRequiredLabel.Width := 106;
    BoardRequiredLabel.Height := 15;
    BoardRequiredLabel.Caption := 'BoardRequiredLabel';
    BoardRequiredLabel.ElementFont := efCSS;
    BoardRequiredLabel.ElementPosition := epIgnore;
    BoardRequiredLabel.HeightStyle := ssAuto;
    BoardRequiredLabel.HeightPercent := 100.000000000000000000;
    BoardRequiredLabel.WidthStyle := ssAuto;
    BoardRequiredLabel.WidthPercent := 100.000000000000000000;
    Media2Panel.SetParentComponent(PainterPanel);
    Media2Panel.Name := 'Media2Panel';
    Media2Panel.Left := 3;
    Media2Panel.Top := 166;
    Media2Panel.Width := 129;
    Media2Panel.Height := 58;
    Media2Panel.ElementClassName := 'card';
    Media2Panel.HeightStyle := ssAuto;
    Media2Panel.WidthStyle := ssAuto;
    Media2Panel.BorderColor := clNone;
    Media2Panel.BorderStyle := bsNone;
    Media2Panel.ChildOrder := 3;
    Media2Panel.Color := clWindow;
    Media2Panel.ElementBodyClassName := 'card-body';
    Media2Panel.ElementFont := efCSS;
    Media2Panel.ElementPosition := epIgnore;
    Media2Panel.TabOrder := 2;
    Media2Panel.Visible := False;
    Media2Label.SetParentComponent(Media2Panel);
    Media2Label.Name := 'Media2Label';
    Media2Label.Left := 3;
    Media2Label.Top := 22;
    Media2Label.Width := 67;
    Media2Label.Height := 15;
    Media2Label.Caption := 'Media2Label';
    Media2Label.ElementFont := efCSS;
    Media2Label.ElementPosition := epIgnore;
    Media2Label.HeightStyle := ssAuto;
    Media2Label.HeightPercent := 100.000000000000000000;
    Media2Label.WidthStyle := ssAuto;
    Media2Label.WidthPercent := 100.000000000000000000;
    AddressLabel.SetParentComponent(PainterDetails);
    AddressLabel.Name := 'AddressLabel';
    AddressLabel.Left := 64;
    AddressLabel.Top := 76;
    AddressLabel.Width := 100;
    AddressLabel.Height := 30;
    AddressLabel.ElementClassName := 'PostalAddress';
    AddressLabel.HeightStyle := ssAuto;
    AddressLabel.WidthStyle := ssAuto;
    AddressLabel.ChildOrder := 13;
    AddressLabel.ElementPosition := epIgnore;
    AddressLabel.ElementFont := efCSS;
    AddressLabel.Role := '';
    HomePhonePanel.SetParentComponent(PainterDetails);
    HomePhonePanel.Name := 'HomePhonePanel';
    HomePhonePanel.Left := 212;
    HomePhonePanel.Top := 303;
    HomePhonePanel.Width := 129;
    HomePhonePanel.Height := 56;
    HomePhonePanel.ElementClassName := 'card';
    HomePhonePanel.HeightStyle := ssAuto;
    HomePhonePanel.WidthStyle := ssAuto;
    HomePhonePanel.BorderColor := clNone;
    HomePhonePanel.BorderStyle := bsNone;
    HomePhonePanel.ChildOrder := 10;
    HomePhonePanel.Color := clWindow;
    HomePhonePanel.ElementBodyClassName := 'card-body';
    HomePhonePanel.ElementFont := efCSS;
    HomePhonePanel.ElementPosition := epIgnore;
    HomePhonePanel.TabOrder := 3;
    HomePhonePanel.Visible := False;
    PhoneLabel.SetParentComponent(HomePhonePanel);
    PhoneLabel.Name := 'PhoneLabel';
    PhoneLabel.Left := 32;
    PhoneLabel.Top := 22;
    PhoneLabel.Width := 62;
    PhoneLabel.Height := 15;
    PhoneLabel.Caption := 'PhoneLabel';
    PhoneLabel.ElementFont := efCSS;
    PhoneLabel.ElementPosition := epIgnore;
    PhoneLabel.HeightStyle := ssAuto;
    PhoneLabel.HeightPercent := 100.000000000000000000;
    PhoneLabel.WidthStyle := ssAuto;
    PhoneLabel.WidthPercent := 100.000000000000000000;
    TravelPanel.SetParentComponent(PainterDetails);
    TravelPanel.Name := 'TravelPanel';
    TravelPanel.Left := 220;
    TravelPanel.Top := 383;
    TravelPanel.Width := 129;
    TravelPanel.Height := 56;
    TravelPanel.ElementClassName := 'card';
    TravelPanel.HeightStyle := ssAuto;
    TravelPanel.WidthStyle := ssAuto;
    TravelPanel.BorderColor := clNone;
    TravelPanel.BorderStyle := bsNone;
    TravelPanel.ChildOrder := 10;
    TravelPanel.Color := clWindow;
    TravelPanel.ElementBodyClassName := 'card-body';
    TravelPanel.ElementFont := efCSS;
    TravelPanel.ElementPosition := epIgnore;
    TravelPanel.TabOrder := 4;
    TravelPanel.Visible := False;
    TransportLabel.SetParentComponent(TravelPanel);
    TransportLabel.Name := 'TransportLabel';
    TransportLabel.Left := 3;
    TransportLabel.Top := 3;
    TransportLabel.Width := 77;
    TransportLabel.Height := 15;
    TransportLabel.Caption := 'TransportLabel';
    TransportLabel.ElementFont := efCSS;
    TransportLabel.ElementPosition := epIgnore;
    TransportLabel.HeightStyle := ssAuto;
    TransportLabel.HeightPercent := 100.000000000000000000;
    TransportLabel.WidthStyle := ssAuto;
    TransportLabel.WidthPercent := 100.000000000000000000;
    OtherTravelLabel.SetParentComponent(TravelPanel);
    OtherTravelLabel.Name := 'OtherTravelLabel';
    OtherTravelLabel.Left := 3;
    OtherTravelLabel.Top := 35;
    OtherTravelLabel.Width := 88;
    OtherTravelLabel.Height := 15;
    OtherTravelLabel.Caption := 'OtherTravelLabel';
    OtherTravelLabel.ElementFont := efCSS;
    OtherTravelLabel.ElementPosition := epIgnore;
    OtherTravelLabel.HeightStyle := ssAuto;
    OtherTravelLabel.HeightPercent := 100.000000000000000000;
    OtherTravelLabel.WidthStyle := ssAuto;
    OtherTravelLabel.WidthPercent := 100.000000000000000000;
    OtherTravelLabel.DataField := 'OtherTravelRequirements';
    OtherTravelLabel.DataSource := GuestSource;
    PriceBreakDown.SetParentComponent(Self);
    PriceBreakDown.Name := 'PriceBreakDown';
    PriceBreakDown.Left := 40;
    PriceBreakDown.Top := 72;
    PriceBreakDown.Width := 233;
    PriceBreakDown.Height := 121;
    PriceBreakDown.ElementClassName := 'card';
    PriceBreakDown.HeightStyle := ssAuto;
    PriceBreakDown.WidthStyle := ssAuto;
    PriceBreakDown.BorderStyle := bsNone;
    PriceBreakDown.ChildOrder := 2;
    PriceBreakDown.ElementBodyClassName := 'card-body';
    PriceBreakDown.ElementFont := efCSS;
    PriceBreakDown.ElementPosition := epIgnore;
    PriceBreakDown.TabOrder := 2;
    PriceBreakDown.Visible := False;
    HolidayBookingCosts.SetParentComponent(PriceBreakDown);
    HolidayBookingCosts.Name := 'HolidayBookingCosts';
    HolidayBookingCosts.Left := 32;
    HolidayBookingCosts.Top := 16;
    HolidayBookingCosts.Width := 145;
    HolidayBookingCosts.Height := 89;
    HolidayBookingCosts.HeightStyle := ssAuto;
    HolidayBookingCosts.WidthStyle := ssAuto;
    HolidayBookingCosts.ElementPosition := epIgnore;
    HolidayBookingCosts.ElementFont := efCSS;
    HolidayBookingCosts.Role := '';
    HolidayDetails.SetParentComponent(Self);
    HolidayDetails.Name := 'HolidayDetails';
    HolidayDetails.Left := 40;
    HolidayDetails.Top := 320;
    HolidayDetails.Width := 233;
    HolidayDetails.Height := 302;
    HolidayDetails.ElementClassName := 'card';
    HolidayDetails.HeightStyle := ssAuto;
    HolidayDetails.WidthStyle := ssAuto;
    HolidayDetails.BorderColor := clNone;
    HolidayDetails.BorderStyle := bsNone;
    HolidayDetails.ChildOrder := 10;
    HolidayDetails.Color := clWindow;
    HolidayDetails.ElementBodyClassName := 'card-body';
    HolidayDetails.ElementFont := efCSS;
    HolidayDetails.ElementPosition := epIgnore;
    HolidayDetails.TabOrder := 3;
    DestinationLabel.SetParentComponent(HolidayDetails);
    DestinationLabel.Name := 'DestinationLabel';
    DestinationLabel.Left := 56;
    DestinationLabel.Top := 32;
    DestinationLabel.Width := 88;
    DestinationLabel.Height := 15;
    DestinationLabel.Caption := 'DestinationLabel';
    DestinationLabel.ElementFont := efCSS;
    DestinationLabel.ElementPosition := epIgnore;
    DestinationLabel.HeightStyle := ssAuto;
    DestinationLabel.HeightPercent := 100.000000000000000000;
    DestinationLabel.WidthStyle := ssAuto;
    DestinationLabel.WidthPercent := 100.000000000000000000;
    HotelLabel.SetParentComponent(HolidayDetails);
    HotelLabel.Name := 'HotelLabel';
    HotelLabel.Left := 57;
    HotelLabel.Top := 64;
    HotelLabel.Width := 57;
    HotelLabel.Height := 15;
    HotelLabel.Caption := 'HotelLabel';
    HotelLabel.ElementFont := efCSS;
    HotelLabel.ElementPosition := epIgnore;
    HotelLabel.HeightStyle := ssAuto;
    HotelLabel.HeightPercent := 100.000000000000000000;
    HotelLabel.WidthStyle := ssAuto;
    HotelLabel.WidthPercent := 100.000000000000000000;
    HolidayLabel.SetParentComponent(HolidayDetails);
    HolidayLabel.Name := 'HolidayLabel';
    HolidayLabel.Left := 57;
    HolidayLabel.Top := 104;
    HolidayLabel.Width := 69;
    HolidayLabel.Height := 15;
    HolidayLabel.Caption := 'HolidayLabel';
    HolidayLabel.ElementFont := efCSS;
    HolidayLabel.ElementPosition := epIgnore;
    HolidayLabel.HeightStyle := ssAuto;
    HolidayLabel.HeightPercent := 100.000000000000000000;
    HolidayLabel.WidthStyle := ssAuto;
    HolidayLabel.WidthPercent := 100.000000000000000000;
    PainterCountLabel.SetParentComponent(HolidayDetails);
    PainterCountLabel.Name := 'PainterCountLabel';
    PainterCountLabel.Left := 57;
    PainterCountLabel.Top := 144;
    PainterCountLabel.Width := 98;
    PainterCountLabel.Height := 15;
    PainterCountLabel.Caption := 'PainterCountLabel';
    PainterCountLabel.ElementFont := efCSS;
    PainterCountLabel.ElementPosition := epIgnore;
    PainterCountLabel.HeightStyle := ssAuto;
    PainterCountLabel.HeightPercent := 100.000000000000000000;
    PainterCountLabel.WidthStyle := ssAuto;
    PainterCountLabel.WidthPercent := 100.000000000000000000;
    RoomTypeLabel.SetParentComponent(HolidayDetails);
    RoomTypeLabel.Name := 'RoomTypeLabel';
    RoomTypeLabel.Left := 57;
    RoomTypeLabel.Top := 216;
    RoomTypeLabel.Width := 84;
    RoomTypeLabel.Height := 15;
    RoomTypeLabel.Caption := 'RoomTypeLabel';
    RoomTypeLabel.ElementFont := efCSS;
    RoomTypeLabel.ElementPosition := epIgnore;
    RoomTypeLabel.HeightStyle := ssAuto;
    RoomTypeLabel.HeightPercent := 100.000000000000000000;
    RoomTypeLabel.WidthStyle := ssAuto;
    RoomTypeLabel.WidthPercent := 100.000000000000000000;
    NPPInfo.SetParentComponent(HolidayDetails);
    NPPInfo.Name := 'NPPInfo';
    NPPInfo.Left := 57;
    NPPInfo.Top := 177;
    NPPInfo.Width := 129;
    NPPInfo.Height := 33;
    NPPInfo.ElementClassName := 'card';
    NPPInfo.HeightStyle := ssAuto;
    NPPInfo.WidthStyle := ssAuto;
    NPPInfo.BorderColor := clNone;
    NPPInfo.BorderStyle := bsNone;
    NPPInfo.ChildOrder := 10;
    NPPInfo.Color := clWindow;
    NPPInfo.ElementBodyClassName := 'card-body';
    NPPInfo.ElementFont := efCSS;
    NPPInfo.ElementPosition := epIgnore;
    NPPInfo.TabOrder := 0;
    NPPInfo.Visible := False;
    NPPCountLabel.SetParentComponent(NPPInfo);
    NPPCountLabel.Name := 'NPPCountLabel';
    NPPCountLabel.Left := 25;
    NPPCountLabel.Top := 3;
    NPPCountLabel.Width := 6;
    NPPCountLabel.Height := 15;
    NPPCountLabel.Caption := '1';
    NPPCountLabel.ElementFont := efCSS;
    NPPCountLabel.ElementPosition := epIgnore;
    NPPCountLabel.HeightStyle := ssAuto;
    NPPCountLabel.HeightPercent := 100.000000000000000000;
    NPPCountLabel.WidthStyle := ssAuto;
    NPPCountLabel.WidthPercent := 100.000000000000000000;
    Room2Panel.SetParentComponent(HolidayDetails);
    Room2Panel.Name := 'Room2Panel';
    Room2Panel.Left := 56;
    Room2Panel.Top := 256;
    Room2Panel.Width := 129;
    Room2Panel.Height := 33;
    Room2Panel.ElementClassName := 'card';
    Room2Panel.HeightStyle := ssAuto;
    Room2Panel.WidthStyle := ssAuto;
    Room2Panel.BorderColor := clNone;
    Room2Panel.BorderStyle := bsNone;
    Room2Panel.ChildOrder := 10;
    Room2Panel.Color := clWindow;
    Room2Panel.ElementBodyClassName := 'card-body';
    Room2Panel.ElementFont := efCSS;
    Room2Panel.ElementPosition := epIgnore;
    Room2Panel.TabOrder := 1;
    Room2Panel.Visible := False;
    RoomType2Label.SetParentComponent(Room2Panel);
    RoomType2Label.Name := 'RoomType2Label';
    RoomType2Label.Left := 3;
    RoomType2Label.Top := 3;
    RoomType2Label.Width := 84;
    RoomType2Label.Height := 15;
    RoomType2Label.Caption := 'RoomTypeLabel';
    RoomType2Label.ElementFont := efCSS;
    RoomType2Label.ElementPosition := epIgnore;
    RoomType2Label.HeightStyle := ssAuto;
    RoomType2Label.HeightPercent := 100.000000000000000000;
    RoomType2Label.WidthStyle := ssAuto;
    RoomType2Label.WidthPercent := 100.000000000000000000;
    HolidayDetailsButton.SetParentComponent(Self);
    HolidayDetailsButton.Name := 'HolidayDetailsButton';
    HolidayDetailsButton.Left := 34;
    HolidayDetailsButton.Top := 248;
    HolidayDetailsButton.Width := 96;
    HolidayDetailsButton.Height := 25;
    HolidayDetailsButton.Caption := 'Holiday Details';
    HolidayDetailsButton.ChildOrder := 7;
    HolidayDetailsButton.ElementClassName := 'btn btn-primary';
    HolidayDetailsButton.ElementFont := efCSS;
    HolidayDetailsButton.ElementPosition := epIgnore;
    HolidayDetailsButton.HeightStyle := ssAuto;
    HolidayDetailsButton.HeightPercent := 100.000000000000000000;
    HolidayDetailsButton.WidthStyle := ssAuto;
    HolidayDetailsButton.WidthPercent := 100.000000000000000000;
    SetEvent(HolidayDetailsButton, Self, 'OnClick', 'HolidayDetailsButtonClick');
    PainterDetailsButton.SetParentComponent(Self);
    PainterDetailsButton.Name := 'PainterDetailsButton';
    PainterDetailsButton.Left := 136;
    PainterDetailsButton.Top := 248;
    PainterDetailsButton.Width := 96;
    PainterDetailsButton.Height := 25;
    PainterDetailsButton.Caption := 'Painter Details';
    PainterDetailsButton.ChildOrder := 7;
    PainterDetailsButton.ElementClassName := 'btn btn-secondary';
    PainterDetailsButton.ElementFont := efCSS;
    PainterDetailsButton.ElementPosition := epIgnore;
    PainterDetailsButton.HeightStyle := ssAuto;
    PainterDetailsButton.HeightPercent := 100.000000000000000000;
    PainterDetailsButton.WidthStyle := ssAuto;
    PainterDetailsButton.WidthPercent := 100.000000000000000000;
    SetEvent(PainterDetailsButton, Self, 'OnClick', 'PainterDetailsButtonClick');
    GuestDetailsButton.SetParentComponent(Self);
    GuestDetailsButton.Name := 'GuestDetailsButton';
    GuestDetailsButton.Left := 238;
    GuestDetailsButton.Top := 248;
    GuestDetailsButton.Width := 96;
    GuestDetailsButton.Height := 25;
    GuestDetailsButton.Caption := 'Guest Details';
    GuestDetailsButton.ChildOrder := 7;
    GuestDetailsButton.ElementClassName := 'btn btn-secondary';
    GuestDetailsButton.ElementFont := efCSS;
    GuestDetailsButton.ElementPosition := epIgnore;
    GuestDetailsButton.HeightStyle := ssAuto;
    GuestDetailsButton.HeightPercent := 100.000000000000000000;
    GuestDetailsButton.WidthStyle := ssAuto;
    GuestDetailsButton.WidthPercent := 100.000000000000000000;
    SetEvent(GuestDetailsButton, Self, 'OnClick', 'GuestDetailsButtonClick');
    OtherInfoButton.SetParentComponent(Self);
    OtherInfoButton.Name := 'OtherInfoButton';
    OtherInfoButton.Left := 336;
    OtherInfoButton.Top := 248;
    OtherInfoButton.Width := 121;
    OtherInfoButton.Height := 25;
    OtherInfoButton.Caption := 'Other Information';
    OtherInfoButton.ChildOrder := 7;
    OtherInfoButton.ElementClassName := 'btn btn-secondary';
    OtherInfoButton.ElementFont := efCSS;
    OtherInfoButton.ElementPosition := epIgnore;
    OtherInfoButton.HeightStyle := ssAuto;
    OtherInfoButton.HeightPercent := 100.000000000000000000;
    OtherInfoButton.WidthStyle := ssAuto;
    OtherInfoButton.WidthPercent := 100.000000000000000000;
    SetEvent(OtherInfoButton, Self, 'OnClick', 'OtherInfoButtonClick');
    CloseButton.Left := 816;
    CloseButton.Top := 728;
    CloseButton.ChildOrder := 8;
    BookingSource.SetParentComponent(Self);
    BookingSource.Name := 'BookingSource';
    BookingSource.DataSet := MainData.BookingDataset;
    BookingSource.Left := 312;
    BookingSource.Top := 304;
    GuestSource.SetParentComponent(Self);
    GuestSource.Name := 'GuestSource';
    GuestSource.DataSet := MainData.GuestDataset;
    GuestSource.Left := 312;
    GuestSource.Top := 368;
  finally
    OtherInfo.AfterLoadDFMValues;
    ExtraNightsBeforeLabel.AfterLoadDFMValues;
    ExtraNightsAfterLabel.AfterLoadDFMValues;
    RoomUpgradeLabel.AfterLoadDFMValues;
    DogsAllowed.AfterLoadDFMValues;
    HasDogLabel.AfterLoadDFMValues;
    HearAboutLabel.AfterLoadDFMValues;
    PainterDetails.AfterLoadDFMValues;
    FirstNameLabel.AfterLoadDFMValues;
    SurnameLabel.AfterLoadDFMValues;
    EmailLabel.AfterLoadDFMValues;
    VaccinatedLabel.AfterLoadDFMValues;
    EmergencyContactNameLabel.AfterLoadDFMValues;
    EmergencyRelationLabel.AfterLoadDFMValues;
    EmergencyTelephoneLabel.AfterLoadDFMValues;
    DietaryReqsLabel.AfterLoadDFMValues;
    MobilityLabel.AfterLoadDFMValues;
    OtherNeedsLabel.AfterLoadDFMValues;
    MobileLabel.AfterLoadDFMValues;
    VaccinatedDate.AfterLoadDFMValues;
    VaccinatedDateLabel.AfterLoadDFMValues;
    PainterPanel.AfterLoadDFMValues;
    Media1Label.AfterLoadDFMValues;
    ChairPanel.AfterLoadDFMValues;
    FoldingChairLabel.AfterLoadDFMValues;
    UKOnlyPanel.AfterLoadDFMValues;
    EaselRequiredLabel.AfterLoadDFMValues;
    BoardRequiredLabel.AfterLoadDFMValues;
    Media2Panel.AfterLoadDFMValues;
    Media2Label.AfterLoadDFMValues;
    AddressLabel.AfterLoadDFMValues;
    HomePhonePanel.AfterLoadDFMValues;
    PhoneLabel.AfterLoadDFMValues;
    TravelPanel.AfterLoadDFMValues;
    TransportLabel.AfterLoadDFMValues;
    OtherTravelLabel.AfterLoadDFMValues;
    PriceBreakDown.AfterLoadDFMValues;
    HolidayBookingCosts.AfterLoadDFMValues;
    HolidayDetails.AfterLoadDFMValues;
    DestinationLabel.AfterLoadDFMValues;
    HotelLabel.AfterLoadDFMValues;
    HolidayLabel.AfterLoadDFMValues;
    PainterCountLabel.AfterLoadDFMValues;
    RoomTypeLabel.AfterLoadDFMValues;
    NPPInfo.AfterLoadDFMValues;
    NPPCountLabel.AfterLoadDFMValues;
    Room2Panel.AfterLoadDFMValues;
    RoomType2Label.AfterLoadDFMValues;
    HolidayDetailsButton.AfterLoadDFMValues;
    PainterDetailsButton.AfterLoadDFMValues;
    GuestDetailsButton.AfterLoadDFMValues;
    OtherInfoButton.AfterLoadDFMValues;
    CloseButton.AfterLoadDFMValues;
    BookingSource.AfterLoadDFMValues;
    GuestSource.AfterLoadDFMValues;
  end;
end;

end.
