unit XData.Dataset.Utils;

interface

uses
  System.SysUtils,
  XData.Web.Dataset,
  WebLib.CDS,
  Data.DB;

type

  // NOTE: All fieldnames are case sensitive

  TXDataDSUtils = class
  public
    class procedure UpdateFieldValue(Dataset: TXDataWebDataset; Info: TResolveResults; const InfoIndex: Integer;
      const FieldName: string; const StayOnRecord: Boolean = True);

    class function UpdateId(Dataset: TXDataWebDataset; Info: TResolveResults; const IdFieldName: string;
      const InfoIndex: Integer): Integer;

    class procedure UpdateAllIds(Dataset: TXDataWebDataset; Info: TResolveResults; const IdFieldName: string;
      const OnlyInserts: Boolean = True);

    class procedure UpdateAllFields(Dataset: TXDataWebDataset; Info: TResolveResults; const InfoIndex: Integer);

    class procedure UpdateAllRecords(Dataset: TXDataWebDataset; Info: TResolveResults);

    // Checks to see if there are any unapplied updates
    class function IsModified(Dataset: TXDataWebDataset): Boolean;
    //Posts and checks modified
    class function CheckIsModified(Dataset: TXDataWebDataset): Boolean;

    class function PostAndApply(Dataset: TXDataWebDataset): Boolean;

    class function ResolvedAsExpected(Info: TResolveResults; const EntityName:
        string; var AMessage: String; const MaxSize: Integer = 1; const MinSize:
        Integer = 1): Boolean;

  end;

implementation

uses
  JS;

{ TXDataDSUtils }

class procedure TXDataDSUtils.UpdateFieldValue(Dataset: TXDataWebDataset; Info: TResolveResults;
  const InfoIndex: Integer; const FieldName: string; const StayOnRecord: Boolean);
var
  BM: TBookmark;
begin
  BM := Dataset.GetBookmark;
  try
    Dataset.GotoBookmark(Info.Records[InfoIndex].BookMark);
    TJSObject(Dataset.CurrentData)[FieldName] := TJSObject(Info.Records[InfoIndex].Data)[FieldName];
  finally
    if not StayOnRecord then
      Dataset.GotoBookmark(BM);
    Dataset.FreeBookmark(BM);
  end;
end;

class function TXDataDSUtils.UpdateId(Dataset: TXDataWebDataset; Info: TResolveResults; const IdFieldName: string;
  const InfoIndex: Integer): Integer;
var
  InfoObject, Data: TJSObject;
  I: Integer;
  lValue: JSValue;
begin
  Dataset.GotoBookmark(Info.Records[InfoIndex].BookMark);
  Data := TJSObject(Dataset.CurrentData);
  InfoObject := TJSObject(Info.Records[InfoIndex].Data);

  lValue := InfoObject[IdFieldName];
  Result := Integer(lValue);
  TJSObject(Dataset.CurrentData)[IdFieldName] := lValue;
end;

class function TXDataDSUtils.CheckIsModified(Dataset: TXDataWebDataset): Boolean;
var lAssigned, lActive, lEmpty: Boolean;
begin

  lAssigned := Assigned(Dataset);
  lActive := Dataset.Active;
  lEmpty := Dataset.IsEmpty;

  if (not Assigned(Dataset)) or (not Dataset.Active) or Dataset.IsEmpty then
     Exit(False);

  if (Dataset.State in dsEditmodes) then
     Dataset.Post;
  Result := IsModified(Dataset);
end;

class function TXDataDSUtils.IsModified(Dataset: TXDataWebDataset): Boolean;
var
  retval: TResolveInfoArray;
begin
  if not Dataset.Active then
     Exit(False);

  retval := Dataset.GetPendingUpdates;
  Result := Assigned(retval) and (Length(retval) > 0);
end;

class function TXDataDSUtils.PostAndApply(Dataset: TXDataWebDataset): Boolean;
begin
 if not Dataset.Active then
     Exit(False);
  if (Dataset.State in dsEditModes) then
    Dataset.Post;
  Result := TXDataDSUtils.IsModified(Dataset);
  if Result then
     Dataset.ApplyUpdates;
end;

class function TXDataDSUtils.ResolvedAsExpected(Info: TResolveResults; const
    EntityName: string; var AMessage: String; const MaxSize: Integer = 1; const
    MinSize: Integer = 1): Boolean;
var
  lSize: Integer;
begin

  AMessage := '';
  lSize := Length(Info.Records);

  if MaxSize > 0 then
  begin
    if lSize > MaxSize then
    begin
      AMessage := 'Oops! not expecting more than ' + MaxSize.ToString + ' ' + EntityName + ' updates. There are ' +
        lSize.ToString + ' returned';
      Exit(False);
    end;
  end;

  if lSize < MinSize then
  begin
    AMessage := 'Oops! we are expecting at least ' + MinSize.ToString + ' ' + EntityName + ' updates. There are ' +
      lSize.ToString + ' returned';
    Exit(False);
  end;

  if Info.Records[0].Error <> '' then
  begin
    AMessage := 'Data update failed: Error Message: ' + Info.Records[0].Error;
    Exit(False);
  end;

  Result := True;

end;

class procedure TXDataDSUtils.UpdateAllFields(Dataset: TXDataWebDataset; Info: TResolveResults;
  const InfoIndex: Integer);
var
  InfoObject, Data: TJSObject;
  I: Integer;
  lKey: string;
begin
  Dataset.GotoBookmark(Info.Records[InfoIndex].BookMark);
  Data := TJSObject(Dataset.CurrentData);
  InfoObject := TJSObject(Info.Records[InfoIndex].Data);

  for I := 0 to Length(TJSObject.keys(InfoObject)) - 1 do
  begin
    lKey := TJSObject.keys(InfoObject)[I];
    if Data.hasOwnProperty(lKey) then
      Data[lKey] := InfoObject[lKey];
  end;

end;

class procedure TXDataDSUtils.UpdateAllIds(Dataset: TXDataWebDataset; Info: TResolveResults; const IdFieldName: string;
  const OnlyInserts: Boolean = True);
var
  I: Integer;
  BM: TBookmark;
begin
  Dataset.DisableControls;
  try
    BM := Dataset.GetBookmark;
    try
      for I := 0 to Length(Info.Records) - 1 do
      begin
        if (not OnlyInserts) or (Info.Records[I].Status = TUpdateStatus.usInserted) then
        begin
          Dataset.GotoBookmark(Info.Records[I].BookMark);
          TJSObject(Dataset.CurrentData)[IdFieldName] := TJSObject(Info.Records[I].Data)[IdFieldName];
        end;
      end;
    finally
      Dataset.GotoBookmark(BM);
      Dataset.FreeBookmark(BM);
    end;
  finally
    Dataset.EnableControls;
  end;
end;

class procedure TXDataDSUtils.UpdateAllRecords(Dataset: TXDataWebDataset; Info: TResolveResults);
var
  I: Integer;
  BM: TBookmark;
begin
  Dataset.DisableControls;
  try
    BM := Dataset.GetBookmark;
    try
      for I := 0 to Length(Info.Records) - 1 do
      begin
        UpdateAllFields(Dataset, Info, I);
      end;
    finally
      Dataset.GotoBookmark(BM);
      Dataset.FreeBookmark(BM);
    end;
  finally
    Dataset.EnableControls;
  end;
end;

end.
