program AlphaHolidays;

uses
  Vcl.Forms,
  WEBLib.Forms,
  HolidayChoice in 'HolidayChoice.pas'{*.html},
  WebForm.Core in '..\XData-Core\Web-Core\WebForm.Core.pas'{*.html},
  MainBookingForm in 'MainBookingForm.pas'{*.html},
  MainDataModule in 'MainDataModule.pas',
  App.Config in '..\XData-Core\Web-Core\App.Config.pas',
  App.Types in '..\XData-Core\Web-Core\App.Types.pas',
  Holiday.ReturnTypes in '..\Shared\Holiday.ReturnTypes.pas',
  BaseBooking in 'BaseBooking.pas'{*.html},
  Booking.Web.Shared in 'Booking.Web.Shared.pas',
  Holiday.Utils in 'Holiday.Utils.pas',
  SMX.Web.Utils in '..\..\SMXiLibs\Web-Core-Utils\SMX.Web.Utils.pas',
  SMX.CRM.Utils in '..\..\SMXiLibs\Web-Core-Utils\SMX.CRM.Utils.pas',
  CustomerDetails in 'CustomerDetails.pas'{*.html},
  BookingRequirements in 'BookingRequirements.pas'{*.html},
  Cyrus.Enumerations in '..\Server\Entities\Cyrus.Enumerations.pas',
  OptionalExtras in 'OptionalExtras.pas'{*.html},
  HolidayPayments in 'HolidayPayments.pas'{*.html},
  DTO_Payments in '..\Shared\DTO_Payments.pas',
  Confirmation in 'Confirmation.pas'{*.html},
  SMX.Web.Document.Utils in '..\..\SMXiLibs\Web-Core-Utils\SMX.Web.Document.Utils.pas',
  XData.Dataset.Utils in '..\XData-Core\Web-Core\XData.Dataset.Utils.pas',
  SMX.Web.Table.Simple in '..\..\SMXiLibs\Web-Core-Utils\SMX.Web.Table.Simple.pas',
  MainForm.Base in '..\XData-Core\Web-Core\MainForm.Base.pas'{*.html},
  smx.webcore.types in '..\XData-Core\Web-Core\smx.webcore.types.pas',
  Auth.Service in '..\XData-Core\Web-Core\Auth.Service.pas',
  SMX.Auth.Shared in '..\XData-Core\Auth\SMX.Auth.Shared.pas',
  AccountMainForm in 'Account\AccountMainForm.pas'{*.html},
  Dataset.Plugins in '..\XData-Core\Web-Core\Dataset.Plugins.pas',
  Grid.Plugins in '..\XData-Core\Web-Core\Grid.Plugins.pas',
  Grid.Settings in '..\XData-Core\Web-Core\Grid.Settings.pas',
  Paginator.Plugins in '..\XData-Core\Web-Core\Paginator.Plugins.pas',
  ErrorForm in 'ErrorForm.pas'{*.html},
  AccountMyBookings in 'Account\AccountMyBookings.pas'{*.html},
  AccountBaseForm in 'Account\AccountBaseForm.pas'{*.html},
  AccountBookingDetails in 'Account\AccountBookingDetails.pas'{*.html},
  AccountBookingPayment in 'Account\AccountBookingPayment.pas'{*.html},
  Payment.Utils in 'Payment.Utils.pas',
  AccountPayConfirmation in 'Account\AccountPayConfirmation.pas'{*.html},
  BookingDetailsFrame in 'BookingDetailsFrame.pas'{*.html},
  AccountDocumentsForm in 'Account\AccountDocumentsForm.pas'{*.html},
  Holiday.Booking.Update in 'Holiday.Booking.Update.pas',
  AccountCustomerDetails in 'Account\AccountCustomerDetails.pas'{*.html};

{$R *.res}

begin
  Application.Initialize;
  Application.MainFormOnTaskbar := True;
  Application.CreateFormDirect(TMainData, MainData);
  Application.Run;
end.
